import React from "react";
import style from "./about.style";
import { Header } from "^molecules/header/header.component";
import { Paragraph } from "^atoms/typography/paragraph.component";

export default (props, events$) => {
  return (
    <>
      <Header
        heading="Household"
        greetingName="Tanya"
        actionText="Consectetur Etiam Sem"
        bg="darkGrey"
        fg={style.black}
      />
      <button value="hello!" onClick={events$.handleClickMeButton}>
        Click me!
      </button>
      <Paragraph variant="paraMediumMedium" p={3}>
        Vestibulum id ligula porta felis euismod semper.Aenean lacinia bibendum
        sed consectetur.Morbi leo risus, porta ac consectetur ac, um at
        eros.Aenean eu leo quam.Pellentesque ornare sem lacinia quam is
        vestibulum.Etiam porta sem malesuada magna mollis euismod posuere erat a
        ante venenatis dapibus posuere velit aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper.Aenean lacinia bibendum
        sed consectetur.Morbi leo risus, porta ac consectetur ac, um at
        eros.Aenean eu leo quam.Pellentesque ornare sem lacinia quam is
        vestibulum.Etiam porta sem malesuada magna mollis euismod posuere erat a
        ante venenatis dapibus posuere velit aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper.Aenean lacinia bibendum
        sed consectetur.Morbi leo risus, porta ac consectetur ac, um at
        eros.Aenean eu leo quam.Pellentesque ornare sem lacinia quam is
        vestibulum.Etiam porta sem malesuada magna mollis euismod posuere erat a
        ante venenatis dapibus posuere velit aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper.Aenean lacinia bibendum
        sed consectetur.Morbi leo risus, porta ac consectetur ac, um at
        eros.Aenean eu leo quam.Pellentesque ornare sem lacinia quam is
        vestibulum.Etiam porta sem malesuada magna mollis euismod posuere erat a
        ante venenatis dapibus posuere velit aliquet.
      </Paragraph>
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Text } from "rebass";

export const Paragraph = ({ variant, children, ...props }) => (
  <Text variant={variant} {...props}>
    {children}
  </Text>
);

Paragraph.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
};

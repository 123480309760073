import { useNavigate } from "@reach/router";
import { useState } from "react";
import { useWindowSize } from "react-use";

import { STEPS } from "^constants/message.constants";

import template from "./registration.template";

export const Registration = (props) => {
  //Start: variable declaration
  const events$ = {
    handleNavigation: null,
  };
  //End: variable declaration

  //Start: state definitions
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [navigating, setNavigating] = useState(false);

  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  //End: state definitions

  //Start: event handler definitions
  events$.handleNavigation = async () => {
    if (page === STEPS.EMAIL_REGISTER) {
      animatedSetPage(STEPS.NAME_REGISTER);
    } else {
      animatedSetPage();
    }
  };
  //End: event handler definitions
  const animatedSetPage = (value = null) => {
    setNavigating(true);
    setTimeout(() => {
      setNavigating(false);
      if (value) {
        setPage(value);
      } else {
        navigate("/");
      }
    }, 200);
  };

  return template(
    props,
    events$,
    page,
    animatedSetPage,
    formValues,
    setFormValues,
    width,
    navigating
  );
};

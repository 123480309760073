import { useNavigate } from "@reach/router";
import { useState } from "react";
import { useWindowSize } from "react-use";

import { CONNECT_ACCOUNT_STEPS } from "^constants/message.constants";

import template from "./connect-bank.template";

export const ConnectBank = (props) => {
  //Start: variable declaration
  const events$ = {
    handleNavigation: null,
  };
  //End: variable declaration

  //Start: state definitions
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [page, setPage] = useState(2);
  const [navigating, setNavigating] = useState(false);
  //End: state definitions

  //Start: event handler definitions
  events$.handleNavigation = async () => {
    if (page === CONNECT_ACCOUNT_STEPS.PRECONNECT_SCREEN) {
      animatedSetPage(CONNECT_ACCOUNT_STEPS.BANK_LIST);
    } else {
      animatedSetPage();
    }
  };
  //End: event handler definitions
  const animatedSetPage = (value = null) => {
    setNavigating(true);
    setTimeout(() => {
      setNavigating(false);
      if (value) {
        setPage(value);
      } else {
        navigate("/");
      }
    }, 200);
  };

  return template(props, events$, page, animatedSetPage, width, navigating);
};

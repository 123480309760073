import { Link } from "@reach/router";
import React from "react";
import { Flex } from "rebass";

import { ReactComponent as ArrowRight } from "^assets/icons/arrowRight.svg";
import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { NotificationMessage } from "^atoms/notification-message/notification-message.component";
import { CTA } from "^atoms/typography/cta.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { AccountList } from "^molecules/account-list/account-list.component";
import { preset } from "^style/preset";
import { UTILS } from "^utilities/common";

export default (props) => {
  return (
    <Flex
      flexDirection="column"
      bg={preset.colors.darkGrey}
      sx={{ height: "100vh" }}
      mb={1}
    >
      <Flex mt={4}>
        <NotificationMessage
          variant={APP.NOTIFICATION_MESSAGE.DARK_SUCCESS}
          headerMessage={UTILS.placeholderFormatter(
            MESSAGES.RENEW_SUCCESS_PAGE.HEADER,
            MESSAGES.APP_NAME,
            props.renewStatus.providerName
          )}
          message={UTILS.placeholderFormatter(
            MESSAGES.RENEW_SUCCESS_PAGE.MESSAGE,
            MESSAGES.APP_NAME,
            props.renewStatus.providerName,
            props.renewStatus.activeDays
          )}
        />
      </Flex>

      <Flex flexDirection="column" mt={2}>
        <AccountList
          cardItems={props.renewStatus.items}
          cardVariant={APP.ACCOUNT_CARD_TYPE.BLUE}
          listVariant={APP.ACCOUNT_LIST_TYPE.BLUE}
        />
      </Flex>

      <Flex justifyContent="center" mt={2} mb={1}>
        <Link to="/accounts">
          <ButtonLarge
            variant={APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
            text={MESSAGES.RENEW_SUCCESS_PAGE.PRIMARY_BUTTON_TEXT}
          />
        </Link>
      </Flex>

      <Flex justifyContent="center" mt={3} mb={3}>
        <Link to="/manageaccounts" style={preset.styles.link}>
          <CTA
            variant={APP.CTA_VARIANT.CTA_SMALL}
            color="white"
            sx={{ float: "left" }}
          >
            {MESSAGES.RENEW_SUCCESS_PAGE.SECONDARY_BUTTON_TEXT}
          </CTA>
          <ArrowRight fill="white" />
        </Link>
      </Flex>
    </Flex>
  );
};

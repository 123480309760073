import { navigate } from "@reach/router";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

import { UTILS } from "^utilities/common";

import template from "./email-verified.template";

export const EmailVerified = (props) => {
  //Start: variable declaration
  const events$ = {
    onContinue: null,
  };
  const { width } = useWindowSize();
  //End: variable declaration

  //Start: state definitions
  const [loading, setLoading] = useState(true);
  //End: state definitions

  //Start: event handler definitions
  events$.onContinue = () => {
    navigate("/");
  };
  //End: event handler definitions

  //Start: effect hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const urlParams = UTILS.getJsonFromUrl(props.location.search);
    let username = urlParams.user_name;
    let authCode = urlParams.code;
    (async function confirmSignUp() {
      try {
        if (username && authCode) {
          await Auth.confirmSignUp(username, authCode);
          setLoading(false);
        }
      } catch (error) {
        console.log("error confirming sign up", error);
      }
    })();
  }, []);
  //End: effect hooks

  return template(events$, width, loading, setLoading);
};

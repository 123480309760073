import React from "react";
import { Flex } from "rebass";

import { UnOrderedListItem } from "^molecules/list/unorderedlist.component";
import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { AccountCard } from "^molecules/account-card/account-card.component";
import { preset } from "^style/preset";
import { H2 } from "^atoms/typography/heading.component";
import { UTILS } from "^utilities/common";
import { APP } from "^utilities/constants/app.constant";
import { MESSAGES } from "^utilities/constants/message.constants";

const messageGroup = [
  MESSAGES.RENEW_PAGE.MESSAGE_ONE,
  MESSAGES.RENEW_PAGE.MESSAGE_TWO,
  MESSAGES.RENEW_PAGE.MESSAGE_THREE,
];

export default (props, events$, providerData) => {
  return (
    <Flex
      flexDirection="column"
      bg={preset.colors.darkGrey}
      sx={{ height: "100vh" }}
    >
      <Flex ml={3} mr={3} flexDirection="column">
        <Flex mt={3} mb={2}>
          <H2 mt={2} color={preset.colors.white}>
            {MESSAGES.RENEW_PAGE.HEADER}
          </H2>
        </Flex>

        <Flex mt={3} flexDirection="column">
          <AccountCard
            variant={APP.ACCOUNT_CARD_TYPE.BLUE}
            imgSrc={providerData.providerImage}
            title={providerData.providerName}
            subText={UTILS.placeholderFormatter(
              MESSAGES.RENEW_PAGE.LINKED_ACCOUNT_MESSAGE,
              providerData.accountCount
            )}
          />
        </Flex>

        <Flex flexDirection="column" mt={3}>
          <UnOrderedListItem
            messages={messageGroup}
            textColor={preset.colors.white}
            number={false}
            bgColor={preset.colors.grey20}
          />
        </Flex>

        <Flex justifyContent="center" mt={4}>
          <ButtonLarge
            variant={APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
            text={MESSAGES.RENEW_PAGE.PRIMARY_BUTTON_TEXT}
            onClick={events$.handleRenew}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

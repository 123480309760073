import React from "react";
import { Box, Flex, Link } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { Steps } from "^atoms/steps/steps.component";
import { CTA } from "^atoms/typography/cta.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { UnOrderedListItem } from "^molecules/list/unorderedlist.component";
import { preset } from "^style/preset";
import { UTILS } from "^utilities/common";

const cssProperty = {
  textColor: preset.colors.white,
};
export default (props, { handleBackToProviders }, width, page) => {
  const { PRECONNECT, COMMON, TERM_PRIVACY } = MESSAGES;
  const { PARAGRAPH_VARIANT, BUTTON_TYPE, CTA_VARIANT } = APP;
  const listParagraphs = [
    UTILS.placeholderFormatter(
      PRECONNECT.MONEYHUB_RETREIVE_MESSAGE,
      "[The app]"
    ),
    UTILS.placeholderFormatter(PRECONNECT.DATA_PROVIDE_MESSAGE, "[The App]"),
    PRECONNECT.PERSONALDATA_NEVER_SHARE,
    PRECONNECT.REAUTHENTICATE_MESSAGE,
    PRECONNECT.AGREE_MESSAGE,
  ];
  const secondListItem = () => {
    return (
      <>
        {UTILS.placeholderFormatter(PRECONNECT.AGREE_LIST_SECOND, "[The App]")}
        <Link>
          <u>{TERM_PRIVACY.HEADER_PRIVACY_MODAL}</u>
        </Link>{" "}
        {PRECONNECT.AND_THE}{" "}
        <Link>
          <u>{PRECONNECT.TERMS_OF_SERVICE}</u>
        </Link>
      </>
    );
  };
  return (
    <>
      <Flex flexDirection="column">
        <Box sx={{ width }} bg={preset.colors.darkGrey}>
          <Steps
            text={UTILS.placeholderFormatter(
              PRECONNECT.CONNECT_TO_PROVIDER,
              "[Provider]"
            )}
            stepNumber={page}
            labelColor={cssProperty.textColor}
            headingColor={cssProperty.textColor}
          />
          <Box p={3}>
            <Paragraph
              sx={{ color: cssProperty.textColor }}
              variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}
            >
              {UTILS.placeholderFormatter(PRECONNECT.CONSENT_INFO, "[The app]")}
            </Paragraph>
          </Box>
          <CommonUnOrderedList
            messages={[PRECONNECT.LIST_ITEM_FIRST, PRECONNECT.LIST_ITEM_SECOND]}
          />
          {listParagraphs.map((message, i) => (
            <Box px={3} pb={3} key={i}>
              <Paragraph
                sx={{ color: cssProperty.textColor }}
                variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}
              >
                {message}
              </Paragraph>
            </Box>
          ))}
          <CommonUnOrderedList
            messages={[
              UTILS.placeholderFormatter(
                PRECONNECT.AGREE_LIST_FIRST,
                "[The App]"
              ),
              secondListItem(),
            ]}
          />

          <Flex justifyContent="center" pt={4}>
            <ButtonLarge
              text={COMMON.CONTINUE}
              variant={BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
            />
          </Flex>
          <Flex justifyContent="center" p={3}>
            <Link onClick={handleBackToProviders}>
              <CTA
                variant={CTA_VARIANT.CTA_SMALL_LETTERS}
                sx={{ color: "white" }}
              >
                {" "}
                {PRECONNECT.BACK_TO_PROVIDERS_MESSAGE}
              </CTA>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

const CommonUnOrderedList = ({ messages }) => {
  return (
    <Box pl={4} pr={4}>
      <UnOrderedListItem
        messages={messages}
        textColor={cssProperty.textColor}
        bgColor={preset.colors.grey20}
      />
    </Box>
  );
};

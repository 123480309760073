import React from "react";

import { Header } from "^molecules/header/header.component";
import { Heading } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";

export const Contact = (props) => {
  return (
    <>
      <Header
        heading="Household"
        greetingName="Tanya"
        actionText="Consectetur Etiam Sem"
        bg="darkGrey"
        fg="white"
      />

      <Heading variant="h1" p={3}>
        Hello World
      </Heading>
      <Paragraph variant="paraMediumMedium" p={3}>
        Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia
        quam venenatis vestibulum. Etiam porta sem malesuada magna mollis
        euismod. Integer posuere erat a ante venenatis dapibus posuere velit
        aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Donec sed odio dui. Donec sed odio dui. Sed posuere consectetur est at
        lobortis. Nullam quis risus eget urna mollis ornare vel eu leo. Vivamus
        sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis
        mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia
        odio sem nec elit. Cras mattis consectetur purus sit amet fermentum.
        Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus
        magna, vel scelerisque nisl consectetur et. Donec id elit non mi porta
        gravida at eget metus. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia
        quam venenatis vestibulum. Etiam porta sem malesuada magna mollis
        euismod. Integer posuere erat a ante venenatis dapibus posuere velit
        aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia
        quam venenatis vestibulum. Etiam porta sem malesuada magna mollis
        euismod. Integer posuere erat a ante venenatis dapibus posuere velit
        aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia
        quam venenatis vestibulum. Etiam porta sem malesuada magna mollis
        euismod. Integer posuere erat a ante venenatis dapibus posuere velit
        aliquet.
      </Paragraph>
      <Paragraph variant="paraMediumRegular" p={3}>
        Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia
        quam venenatis vestibulum. Etiam porta sem malesuada magna mollis
        euismod. Integer posuere erat a ante venenatis dapibus posuere velit
        aliquet.
      </Paragraph>
    </>
  );
};

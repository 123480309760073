import { injectGlobal } from "emotion";
import GraphikRegular from "./Graphik-Regular-Web.woff2";
import GraphikMedium from "./Graphik-Medium-Web.woff2";
import GraphikLight from "./Graphik-Light-Web.woff2";
import GraphikBold from "./Graphik-Bold-Web.woff2";
import GraphikSemiBold from "./Graphik-Semibold-Web.woff2";

export const globalFonts = () => {
  injectGlobal`
 @font-face {
    font-family: "GraphikRegular";
    src: url(${GraphikRegular}) format("woff2");
  }
 @font-face {
    font-family: "GraphikMedium";
    src: url(${GraphikMedium}) format("woff2");
  }
  @font-face {
    font-family: "GraphikLight";
    src: url(${GraphikLight}) format("woff2");
  }
  @font-face {
    font-family: "GraphikBold";
    src: url(${GraphikBold}) format("woff2");
  }
  @font-face {
    font-family: "GraphikSemiBold";
    src: url(${GraphikSemiBold}) format("woff2");
  }
`;
};

import React from "react";
import PropTypes from "prop-types";

import { Box, Flex } from "rebass";

import { AccountCard } from "^molecules/account-card/account-card.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";
import { preset } from "style/preset";
import { UTILS } from "utilities/common";

const {
  ACCOUNT_CARD_TYPE,
  ACCOUNT_LIST_TYPE,
  LABEL_VARIANT_TYPE,
  ACCOUNT_TYPES,
} = APP;
const { accountListVariants, colors } = preset;
const { LABEL_MEDIUM_REGULAR_LITE } = LABEL_VARIANT_TYPE;
const { getNumberData } = UTILS;

export const AccountList = (props) => {
  const {
    title,
    value,
    currency,
    cardItems,
    listVariant = ACCOUNT_LIST_TYPE.LIGHT,
    cardVariant = ACCOUNT_CARD_TYPE.LIGHT,
  } = props;
  const { boxSx, textColor, cardContainerStyle } = accountListVariants[
    listVariant
  ];
  const numData = getNumberData(value);
  const noBottomBorder = listVariant === ACCOUNT_LIST_TYPE.TRANSPARENT;

  return (
    <Box {...props} sx={boxSx}>
      <Flex justifyContent="center" flexDirection="column">
        {title && value && (
          <Flex sx={{ margin: "auto 4px 12px 4px" }}>
            <Box width={0.5}>
              <Label
                variant={LABEL_MEDIUM_REGULAR_LITE}
                sx={{ color: colors[textColor] }}
              >
                {ACCOUNT_TYPES[title.toUpperCase()] || title}
              </Label>
            </Box>
            <Box
              width={0.5}
              sx={{
                textAlign: "right",
                margin: "auto",
              }}
            >
              <Label variant={LABEL_MEDIUM_REGULAR_LITE}>
                {(numData.isNegative ? "-" : "") + currency + numData.value}
              </Label>
            </Box>
          </Flex>
        )}
        {cardItems.map((cardItemProps, i) => (
          <Box
            key={`accCard${i}`}
            sx={
              noBottomBorder
                ? i !== cardItems.length - 1 && cardContainerStyle
                : cardContainerStyle
            }
          >
            <AccountCard {...cardItemProps} variant={cardVariant} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

AccountList.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string,
  cardItems: PropTypes.array,
  listVariant: PropTypes.oneOf([
    ACCOUNT_LIST_TYPE.DARK,
    ACCOUNT_LIST_TYPE.LIGHT,
    ACCOUNT_LIST_TYPE.BLUE,
    ACCOUNT_LIST_TYPE.TRANSPARENT,
  ]),
  cardVariant: PropTypes.oneOf([
    ACCOUNT_CARD_TYPE.DARK,
    ACCOUNT_CARD_TYPE.LIGHT,
    ACCOUNT_CARD_TYPE.BLUE,
    ACCOUNT_CARD_TYPE.TRANSPARENT,
  ]),
};

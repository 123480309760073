import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Image } from "rebass";

import { Label } from "^atoms/typography/label.component";
import { Heading } from "^atoms/typography/heading.component";
import { CTA } from "^atoms/typography/cta.component";
import { preset } from "style/preset";

const raddi = 15;
const cardIconSize = 50;
const actionIconSize = 30;

export const Card = (props) => (
  <Flex flexDirection="column">
    <Box
      {...props}
      p={3}
      color={props.fg}
      bg={props.bg}
      sx={{
        borderTopLeftRadius: raddi,
        borderTopRightRadius: raddi,
      }}
    >
      <Box as={props.cardIcon} width={48} height={48} fill={props.fg} />
      <Box mt={2} mb={0}>
        <Label variant="labelMediumRegular">{props.title}</Label>
        {props.title ? (
          <Label variant="labelLargeMedium">{props.message}</Label>
        ) : (
          <Heading as="h4" variant="h4">
            {props.message}
          </Heading>
        )}
      </Box>
    </Box>
    {props.progress && (
      <Flex bg={props.bg} justifyContent="center" p={2} flexDirection="column">
        {props.progress}
      </Flex>
    )}
    <Box
      bg={props.actionText && props.actionIcon ? `${props.bg}Light` : props.bg}
      sx={{
        borderBottomLeftRadius: raddi,
        borderBottomRightRadius: raddi,
      }}
    >
      <Flex alignItems="center" height={48}>
        <CTA variant="ctaExtraSmall" p={3}>
          {props.actionText}
        </CTA>
        <Flex flexGrow={1} justifyContent="flex-end">
          <Box
            as={props.actionIcon}
            width={actionIconSize}
            height={actionIconSize}
            mr={3}
          />
        </Flex>
      </Flex>
    </Box>
  </Flex>
);

Card.propTypes = {
  c: PropTypes.number,
  bg: PropTypes.string,
  fg: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  cardIcon: PropTypes.string,
  actionBackground: PropTypes.string,
  actionIcon: PropTypes.string,
  actionText: PropTypes.string,
  progress: PropTypes.object,
};

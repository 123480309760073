import { useNavigate } from "@reach/router";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useWindowSize } from "react-use";
import { UTILS } from "utilities/common";

import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";

import template from "./reset-password.template";

export const ResetPassword = (props) => {
  //Start: variable declaration
  const events$ = {
    handleValidation: null,
    handleSubmission: null,
  };
  //End: variable declaration

  //Start: state definitions
  const width = useWindowSize();
  const navigate = useNavigate();
  const [showSaveButton, setShowSaveButton] = useState(false);
  //End: state definitions

  //Start: event handler definitions
  events$.handleValidation = (values) => {
    const errors = {};
    if (!APP.REGEX.PASSWORD_VALIDATION.test(values.password)) {
      errors.password = ERRORS.VALIDATION.PASSWORD_ERROR_MESSAGE;
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = ERRORS.VALIDATION.CONFIRM_PASSWORD_ERROR_MESSAGE;
    }
    if (errors && (errors.password || errors.confirmPassword)) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }

    return errors;
  };

  events$.handleSubmission = async (values, actions) => {
    try {
      const urlParams = UTILS.getJsonFromUrl(props.location.search);

      let username = urlParams.user_name;
      let code = urlParams.code;
      let newPassword = values.password;

      await Auth.forgotPasswordSubmit(username, code, newPassword);
      navigate("/");
    } catch (error) {
      //ToDo: implement path when Auth.forgotPasswordSubmit() api fails.
      console.log("api got failed ", error);
    }
    actions.setSubmitting(false);
  };
  //End: event handler definitions

  return template(events$, width, showSaveButton);
};

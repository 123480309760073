import PropTypes from "prop-types";
import React from "react";
import { Flex } from "rebass";

import { APP } from "^constants/app.constant";
import { AccountConnectionList } from "^molecules/account-conn-list/account-conn-list.component";

import { getListData } from "./account-connections-list-view.service";

const {
  ACCOUNT_CONNECTION_LIST_TYPE: LIST_VARIANTS,
  ACCOUNT_CONNECTION_CARD_TYPE: CARD_VARIANTS,
} = APP;

export const AccConnsListView = ({ accountsData, ...props }) => {
  const allListData = getListData(
    accountsData,
    props.handleRenew,
    props.handleDisconnect
  );
  return (
    <Flex justifyContent="center" flexDirection="column" {...props}>
      {allListData && (
        <AccountConnectionList
          cardItems={allListData}
          listVariant={LIST_VARIANTS.BLUE}
          cardVariant={CARD_VARIANTS.DARK}
        />
      )}
    </Flex>
  );
};

AccConnsListView.propTypes = {
  accountsData: PropTypes.array,
};

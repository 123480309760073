import { API } from "aws-amplify";
import * as mutations from "graphql/mutations";

export default {
  updateOnBoardedStatus: async (userID) => {
    return API.graphql({
      query: mutations.updateDashboardStatus,
      variables: {
        input: {
          id: userID,
          onBoarded: true,
        },
      },
    });
  },
};

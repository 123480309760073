import { navigate } from "@reach/router";
// import Provider from "assets/icons/monzo.png";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { setRenewStatus } from "reducers/accountReducer";

// import { store } from "^app/store";

import template from "./bank-provider.template";

export const BankProvider = (props) => {
  const events$ = {
    handleResponse: null,
  };

  events$.handleResponse = (status) => {
    navigate("connecting", {
      state: {
        bankResponse: status,
      },
      replace: true,
    });
  };

  return template(props, events$);
};

import React from "react";
import { Flex, Image } from "rebass";

import { H3 } from "^atoms/typography/heading.component";
import { preset } from "^style//preset";
import { MESSAGES } from "^utilities/constants/message.constants";

export default (props, providerData) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      bg={preset.colors.darkGrey}
      sx={{ height: "100vh" }}
    >
      <Flex>
        <Image
          src={providerData.providerImage}
          sx={{ marginTop: "31px" }}
          alt="Provider"
          width={56}
        />
      </Flex>

      <Flex mt={3}>
        <H3 color={preset.colors.white}>
          {MESSAGES.ACCOUNT.CONNECTING_MESSAGE}
        </H3>
      </Flex>
    </Flex>
  );
};

import { navigate } from "@reach/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRenewStatus } from "reducers/accountReducer";

import { store } from "^app/store";

import template from "./connecting.template";

export const Connecting = (props) => {
  const dispatch = useDispatch();
  const providerData = store.getState().account.renewAccount;

  useEffect(() => {
    let bankResponse = false;
    if (props.location.state) {
      if (props.location.state.bankResponse) bankResponse = true;
    }

    // TODO : get renew staus from api
    let updatedData = {
      ...providerData,
      status: bankResponse,
      activeDays: 90,
    };
    dispatch(setRenewStatus(updatedData));
    setTimeout(() => {
      navigate("/renew-status", { replace: true });
    }, 2000);
  }, []);

  return template(props, providerData);
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { animated, useSpring } from "react-spring";

import { ReactComponent as ArrowOpen } from "assets/icons/arrowOpen.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg";

import { APP } from "^constants/app.constant";
import preset from "style/preset";
import { Label } from "^atoms/typography/label.component";
import { SquareImage } from "^atoms/image/image.square.component";
import { ButtonMedium } from "^atoms/buttons/button.medium.component";
import { TextLink } from "^atoms/typography/text-link.component";
import { Icon } from "^atoms/icon/icon.component";
import { AccountList } from "^molecules/account-list/account-list.component";
import { ExpandCollapse } from "^atoms/expand-collapse/expand-collapse.component";

const { ACCOUNT_CARD_TYPE, ACCOUNT_LIST_TYPE } = APP;

const {
  ACCOUNT_CONNECTION_CARD_TYPE: CARD_VARIANTS,
  LABEL_VARIANT_TYPE,
  IMAGE_TYPE,
  BUTTON_TYPE,
  CTA_VARIANT,
} = APP;
const {
  LABEL_SMALL_MEDIUM,
  LABEL_SMALL_REGULAR_LITE,
  LABEL_SMALL_REGULAR_DARK,
  LABEL_LARGE_MEDIUM,
} = LABEL_VARIANT_TYPE;
const { MEDIUM_PRIMARY_LIGHT } = BUTTON_TYPE;
const { CTA_TEXT_LINK } = CTA_VARIANT;
const { accountConnectionCardVariants, sizes } = preset;

export const AccountConnectionCard = ({
  refreshTime,
  title,
  accountItems,
  imgSrc,
  connEndDate,
  primaryButton,
  secondaryButton,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { cardVariant = CARD_VARIANTS.DARK } = props;
  const theme = accountConnectionCardVariants[cardVariant];

  const spin = useSpring({
    config: { friction: 10 },
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  });

  const GetAccountLabel = () =>
    accountItems.length === 1 ? (
      <Label sx={theme.labelTopSx} variant={LABEL_SMALL_REGULAR_LITE}>
        {accountItems[0].subText}
      </Label>
    ) : (
      <Flex onClick={() => setIsOpen(!isOpen)} sx={theme.multiAccDdlSx}>
        <Box>
          <Label sx={theme.labelTopSx} variant={LABEL_SMALL_REGULAR_DARK}>
            {`${accountItems.length} accounts linked`}
          </Label>
        </Box>
        <Flex sx={theme.labelTopSx} flexGrow={1}>
          <animated.div style={spin}>
            <Icon
              icon={ArrowOpen}
              iconPos={"left"}
              sx={{ color: "white", fill: "white" }}
            />
          </animated.div>
        </Flex>
      </Flex>
    );

  return (
    <Box sx={theme.cardSx}>
      <Flex sx={theme.topSx}>
        <Box width={"100%"}>
          {refreshTime && (
            <Label
              sx={theme.labelTopSx}
              variant={`${LABEL_SMALL_REGULAR_LITE}`}
            >
              {refreshTime}
            </Label>
          )}
          <Label sx={theme.labelTopSx} variant={LABEL_LARGE_MEDIUM}>
            {title}
          </Label>
          {accountItems && accountItems.length > 0 && <GetAccountLabel />}
        </Box>
        {imgSrc && (
          <Box width={"13%"}>
            <SquareImage
              isCurved={true}
              imgSrc={imgSrc}
              imgType={IMAGE_TYPE.OTHERS}
              size={sizes.cardImage}
              title={title}
            />
          </Box>
        )}
      </Flex>
      {accountItems && accountItems.length > 1 && (
        <ExpandCollapse isOpen={isOpen}>
          <Box sx={theme.innerContentSx}>
            <AccountList
              cardItems={accountItems}
              listVariant={ACCOUNT_LIST_TYPE.TRANSPARENT}
              cardVariant={ACCOUNT_CARD_TYPE.TRANSPARENT}
            />
          </Box>
        </ExpandCollapse>
      )}
      <Flex sx={theme.bottomSx} flexDirection="column">
        <Box>
          <Label sx={theme.labelBottomSx} variant={LABEL_SMALL_MEDIUM}>
            {connEndDate}
          </Label>
        </Box>
        <Flex sx={{ marginTop: "12px" }}>
          <ButtonMedium
            icon={ArrowRight}
            iconPos={"right"}
            text={primaryButton.text}
            onClick={() => {
              primaryButton.onClick(accountItems);
            }}
            variant={MEDIUM_PRIMARY_LIGHT}
          />
          <TextLink
            variant={CTA_TEXT_LINK}
            onClick={() => {
              secondaryButton.onClick(accountItems);
            }}
          >
            {secondaryButton.text}
          </TextLink>
        </Flex>
      </Flex>
    </Box>
  );
};

AccountConnectionCard.propTypes = {
  refreshTime: PropTypes.string,
  title: PropTypes.string.isRequired,
  accountItems: PropTypes.array.isRequired,
  imgSrc: PropTypes.any,
  connEndDate: PropTypes.string.isRequired,
  primaryButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.any,
  }),
  secondaryButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.any,
  }),
  cardVariant: PropTypes.oneOf([
    CARD_VARIANTS.DARK,
    CARD_VARIANTS.LIGHT,
    CARD_VARIANTS.BLUE,
  ]),
};

import { navigate } from "@reach/router";
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";

import { Button } from "^atoms/buttons/button.component";
import { ButtonMedium } from "^atoms/buttons/button.medium.component";
import { H2 } from "^atoms/typography/heading.component";
import { Label } from "^atoms/typography/label.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { TextLink } from "^atoms/typography/text-link.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { OrderedListItem } from "^molecules/list/orderedlist.component";
import { Modal } from "^molecules/modal/modal.component";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { ConnectAccountInfo } from "^organisms/secure-link-info/secure-link-info.component";

export default (
  width,
  isModalOpen,
  setIsModalOpen,
  { onModalButtonClick, onSkipClick }
) => {
  const onBankAccountClick = () => {
    navigate("/connectbank");
  };
  const { CONNECT_ACCOUNT_MESSAGES } = MESSAGES;
  const { PARAGRAPH_VARIANT, BUTTON_TYPE, LABEL_VARIANT_TYPE } = APP;

  return (
    <Box>
      <NavBar
        regScreens
        bg={preset.colors.greyBG}
        textColor={preset.colors.darkGrey}
        noBurgerIcon
      />
      <Box sx={{ width, height: "100vh" }} bg={preset.colors.greyBG}>
        <Box p={3}>
          <H2>{CONNECT_ACCOUNT_MESSAGES.HEADER_MESSAGE}</H2>
        </Box>
        <Box pl={3} pr={3}>
          <Paragraph variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
            {CONNECT_ACCOUNT_MESSAGES.CONTENT_MESSAGE}
          </Paragraph>
          <Paragraph
            variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}
            mt={2}
            mb={2}
          >
            {CONNECT_ACCOUNT_MESSAGES.CONTENT_SUB_MESSAGE}
          </Paragraph>
        </Box>
        <Box pl={3} pr={3} pt={3}>
          <OrderedListItem
            messages={[
              CONNECT_ACCOUNT_MESSAGES.LIST_FIRST_POINT,
              CONNECT_ACCOUNT_MESSAGES.LIST_SECOND_POINT,
              CONNECT_ACCOUNT_MESSAGES.LIST_THIRD_POINT,
            ]}
            textwidth="300px"
            bgColor={preset.colors.grey20}
          />
        </Box>
        <Flex justifyContent="center" p={2}>
          <Button
            variant={BUTTON_TYPE.LARGE_PRIMARY_DARK}
            text={CONNECT_ACCOUNT_MESSAGES.CONNECT_BANK_BUTTON_TEXT}
            onClick={onBankAccountClick}
          />
        </Flex>
        <TextLink
          onClick={onModalButtonClick}
          variant={LABEL_VARIANT_TYPE.LABEL_SMALL_MEDIUM}
          sx={{ display: "flex", justifyContent: "center", paddingTop: "18px" }}
        >
          {CONNECT_ACCOUNT_MESSAGES.SECURE_LINK_MESSAGE}
        </TextLink>
        <Flex justifyContent="center" paddingTop="12px">
          <ButtonMedium
            text={CONNECT_ACCOUNT_MESSAGES.SKIP_BUTTON_TEXT}
            variant={BUTTON_TYPE.MEDIUM_PRIMARY_LIGHT}
            onClick={onSkipClick}
            icon={ArrowRight}
            iconPos="right"
            bgChange
          />
        </Flex>
        <Label
          variant={LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR}
          ml={5}
          mr={5}
          mt={3}
          mb={3}
          sx={{ textAlign: "center" }}
        >
          {CONNECT_ACCOUNT_MESSAGES.ACCOUNT_FOOTER_MESSAGE}
        </Label>
      </Box>
      <Modal
        bg="white"
        fg="darkGrey"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dismissText="Close"
        content={<ConnectAccountInfo />}
      />
    </Box>
  );
};

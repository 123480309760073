import PropTypes from "prop-types";
import React from "react";

import { OrderedListItem } from "./orderedlist.component";

const height = "7px";
const width = "7px";
export function UnOrderedListItem({ textColor, messages, bgColor, ...props }) {
  return (
    <OrderedListItem
      messages={messages}
      textColor={textColor}
      height={height}
      width={width}
      number={false}
      bgColor={bgColor}
      {...props}
    />
  );
}

UnOrderedListItem.propTypes = {
  textColor: PropTypes.string,
  messages: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
};

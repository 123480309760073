import { ReactComponent as AddIcon } from "assets/icons/addFilled.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import React from "react";
import { Box, Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { Icon } from "^atoms/icon/icon.component";
import { H2 } from "^atoms/typography/heading.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { AccountConnectionList } from "^molecules/account-conn-list/account-conn-list.component";
import { Modal } from "^molecules/modal/modal.component";
import { AccConnsListView } from "^organisms/account-connections-list-view/account-connections-list-view.component";
import { DisconnectAccountModal } from "^organisms/disconnect-account-modal/disconnect-account-modal.component";

const {
  BUTTON_TYPE,
  LABEL_VARIANT_TYPE,
  ACCOUNT_CONNECTION_LIST_TYPE: LIST_VARIANTS,
  ACCOUNT_CONNECTION_CARD_TYPE: CARD_VARIANTS,
  VARIANTS,
} = APP;
const { ACCOUNT } = MESSAGES;

export default (
  accountsData,
  showAddAccount,
  $events,
  showDisconnect,
  setShowDisconnect,
  deleteAccountData
) => {
  return (
    <Box variant={VARIANTS.MANAGE_ACCOUNTS}>
      <Box sx={{ p: 3, bg: "darkGrey", color: "white" }}>
        <H2 sx={{ width: "80%" }}>{ACCOUNT.MANAGE_CONNECTIONS}</H2>
      </Box>
      <Flex sx={{ p: "0 16px", bg: "darkGrey", color: "white" }}>
        <Icon
          icon={RefreshIcon}
          iconPos={"left"}
          sx={{
            width: "20px",
            height: "20px",
            fill: "white",
            marginRight: "6px",
          }}
        />
        <Label variant={VARIANTS.REFRESH_TEXT}>Refresh</Label>
      </Flex>
      {accountsData && (
        <AccConnsListView
          accountsData={accountsData}
          handleRenew={$events.handleRenew}
          handleDisconnect={$events.handleDisconnect}
        />
      )}
      {showAddAccount && (
        <Box variant={VARIANTS.ADD_ACCOUNT}>
          <ButtonLarge
            icon={AddIcon}
            variant={BUTTON_TYPE.LARGE_SECONDARY_OUTLINE_DARK}
            text={ACCOUNT.ADD_BUTTON_TEXT}
            textVariant={LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR_DARK}
            iconPos={"left"}
            buttonSx={{ width: "100%" }}
          />
        </Box>
      )}

      <Modal
        type="danger"
        bg="white"
        fg="white"
        height="inherit"
        bottom="initial"
        top="20%"
        content={
          <DisconnectAccountModal
            provider={showDisconnect ? deleteAccountData.providerName : ""}
          />
        }
        isModalOpen={showDisconnect}
        setIsModalOpen={() => setShowDisconnect(!showDisconnect)}
        dismissText="No, I want to keep this connection"
        handleLinkAction={$events.handleDisconnectConfirm}
        showTextLink
        textForTextLink="Yes, disconnect now"
        hideFooter
      />
    </Box>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { preset } from "style/preset";

import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

import { ReactComponent as notificationError } from "assets/icons/notificationError.svg";
import { ReactComponent as notificationInfo } from "assets/icons/notificationInfo.svg";
import { ReactComponent as notificationSuccess } from "assets/icons/notificationSuccess.svg";
import { ReactComponent as notificationWarning } from "assets/icons/notificationWarning.svg";

const typeSmoothValues = {
  danger: preset.colors.redSmooth,
  info: preset.colors.blueSmooth,
  warning: preset.colors.yellowSmooth,
  success: preset.colors.greenSmooth,
};

const typeBaseValues = {
  danger: preset.colors.redBase,
  info: preset.colors.blueBase,
  warning: preset.colors.yellowBase,
  success: preset.colors.greenBase,
};

const typeIconValues = {
  danger: notificationError,
  info: notificationInfo,
  warning: notificationWarning,
  success: notificationSuccess,
};

export const Alert = (props) => {
  return (
    <Box
      mb={3}
      mt={2}
      color={preset.colors.darkGrey}
      bg={typeSmoothValues[props.type]}
      variant={APP.ALERT_VARIANTS.ALERT_GLOBAL}
      sx={{
        border: `1px solid ${typeBaseValues[props.type]}`,
      }}
    >
      <Box
        as={typeIconValues[props.type]}
        width={20}
        height={20}
        fill={typeBaseValues[props.type]}
        variant={APP.ALERT_VARIANTS.ALERT_IMAGE}
      />
      <Label variant={APP.ALERT_VARIANTS.ALERT_MESSAGE}>{props.message}</Label>
    </Box>
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

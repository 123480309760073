import React from "react";

import { Header } from "^molecules/header/header.component";

export const Profile = () => {
  return (
    <>
      <Header
        heading="Profile"
        greetingName="Tanya"
        actionText="Consectetur Etiam Sem"
        bg="skyblueSmooth"
        fg="darkGrey"
      />
    </>
  );
};

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDashboardStatus = /* GraphQL */ `
  mutation CreateDashboardStatus(
    $input: CreateDashboardStatusInput!
    $condition: ModelDashboardStatusConditionInput
  ) {
    createDashboardStatus(input: $input, condition: $condition) {
      id
      user
      registered
      setupConnectedAccounts
      setupMonthlyPlan
      setupTimePeriod
      setupGoal
      setupCreditScore
      firstTimeLogin
      onBoarded
      dashboardCards {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardStatus = /* GraphQL */ `
  mutation UpdateDashboardStatus(
    $input: UpdateDashboardStatusInput!
    $condition: ModelDashboardStatusConditionInput
  ) {
    updateDashboardStatus(input: $input, condition: $condition) {
      id
      user
      registered
      setupConnectedAccounts
      setupMonthlyPlan
      setupTimePeriod
      setupGoal
      setupCreditScore
      firstTimeLogin
      onBoarded
      dashboardCards {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardStatus = /* GraphQL */ `
  mutation DeleteDashboardStatus(
    $input: DeleteDashboardStatusInput!
    $condition: ModelDashboardStatusConditionInput
  ) {
    deleteDashboardStatus(input: $input, condition: $condition) {
      id
      user
      registered
      setupConnectedAccounts
      setupMonthlyPlan
      setupTimePeriod
      setupGoal
      setupCreditScore
      firstTimeLogin
      onBoarded
      dashboardCards {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      name
      total {
        value
        currency
      }
      dateCreated
      progressPercentage
      progressAmount {
        value
        currency
      }
      accounts {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      name
      total {
        value
        currency
      }
      dateCreated
      progressPercentage
      progressAmount {
        value
        currency
      }
      accounts {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      name
      total {
        value
        currency
      }
      dateCreated
      progressPercentage
      progressAmount {
        value
        currency
      }
      accounts {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      accountName
      balance {
        value
        currency
      }
      details {
        AER
        APR
        sortCodeAccountNumber
        iban
        creditLimit
        endDate
        fixedDate
        interestFreePeriod
        interestType
        linkedProperty
        monthlyRepayment
        overdraftLimit
        postcode
        runningCost
        runningCostPeriod
        term
        yearlyAppreciation
      }
      dateAdded
      dateModified
      id
      providerName
      providerReference
      connectionId
      providerId
      accountReference
      accountHolderName
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      accountName
      balance {
        value
        currency
      }
      details {
        AER
        APR
        sortCodeAccountNumber
        iban
        creditLimit
        endDate
        fixedDate
        interestFreePeriod
        interestType
        linkedProperty
        monthlyRepayment
        overdraftLimit
        postcode
        runningCost
        runningCostPeriod
        term
        yearlyAppreciation
      }
      dateAdded
      dateModified
      id
      providerName
      providerReference
      connectionId
      providerId
      accountReference
      accountHolderName
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      accountName
      balance {
        value
        currency
      }
      details {
        AER
        APR
        sortCodeAccountNumber
        iban
        creditLimit
        endDate
        fixedDate
        interestFreePeriod
        interestType
        linkedProperty
        monthlyRepayment
        overdraftLimit
        postcode
        runningCost
        runningCostPeriod
        term
        yearlyAppreciation
      }
      dateAdded
      dateModified
      id
      providerName
      providerReference
      connectionId
      providerId
      accountReference
      accountHolderName
      type
      createdAt
      updatedAt
    }
  }
`;
export const createBankIcons = /* GraphQL */ `
  mutation CreateBankIcons(
    $input: CreateBankIconsInput!
    $condition: ModelBankIconsConditionInput
  ) {
    createBankIcons(input: $input, condition: $condition) {
      id
      source
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateBankIcons = /* GraphQL */ `
  mutation UpdateBankIcons(
    $input: UpdateBankIconsInput!
    $condition: ModelBankIconsConditionInput
  ) {
    updateBankIcons(input: $input, condition: $condition) {
      id
      source
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteBankIcons = /* GraphQL */ `
  mutation DeleteBankIcons(
    $input: DeleteBankIconsInput!
    $condition: ModelBankIconsConditionInput
  ) {
    deleteBankIcons(input: $input, condition: $condition) {
      id
      source
      type
      createdAt
      updatedAt
    }
  }
`;
export const createBank = /* GraphQL */ `
  mutation CreateBank(
    $input: CreateBankInput!
    $condition: ModelBankConditionInput
  ) {
    createBank(input: $input, condition: $condition) {
      id
      name
      type
      isBeta
      country
      parentRef
      bankRef
      accountTypes {
        beta
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBank = /* GraphQL */ `
  mutation UpdateBank(
    $input: UpdateBankInput!
    $condition: ModelBankConditionInput
  ) {
    updateBank(input: $input, condition: $condition) {
      id
      name
      type
      isBeta
      country
      parentRef
      bankRef
      accountTypes {
        beta
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBank = /* GraphQL */ `
  mutation DeleteBank(
    $input: DeleteBankInput!
    $condition: ModelBankConditionInput
  ) {
    deleteBank(input: $input, condition: $condition) {
      id
      name
      type
      isBeta
      country
      parentRef
      bankRef
      accountTypes {
        beta
        name
      }
      createdAt
      updatedAt
    }
  }
`;

import { createAction, createReducer } from "@reduxjs/toolkit";

export const setUserAction = createAction("app/user");
export const currentPageAction = createAction("nav/currentpage");
export const navBarHeightAction = createAction("nav/navbarheight");
export const headerHeightAction = createAction("page/headerheight");
export const fixedHeaderAction = createAction("app/fixedheaderaction");
export const fixedNavBarAction = createAction("app/fixednavbaraction");

export const appReducer = createReducer(
  {
    user: null,
    isFixedNavBar: true,
    isFixedHeader: true,
    currentPage: null,
    navBarHeight: null,
    headerHeight: null,
  },
  {
    [setUserAction]: (state, action) =>
      (state = { ...state, user: action.payload }),
    [currentPageAction]: (state, action) =>
      (state = { ...state, currentPage: action.payload }),
    [navBarHeightAction]: (state, action) =>
      (state = { ...state, navBarHeight: action.payload }),
    [headerHeightAction]: (state, action) =>
      (state = { ...state, headerHeight: action.payload }),
    [fixedHeaderAction]: (state, action) =>
      (state = { ...state, isFixedHeader: action.payload }),
    [fixedNavBarAction]: (state, action) =>
      (state = { ...state, isFixedNavBar: action.payload }),
  }
);

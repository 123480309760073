import { API, graphqlOperation } from "aws-amplify";
import * as queries from "graphql/queries";

import { APP } from "^constants/app.constant";
import { ICONS } from "^constants/icons.constant";
import { MESSAGES } from "^constants/message.constants";

const { CURRENCY_SYMBOL, ACCOUNT_TYPES } = APP;
const { ACCOUNT } = MESSAGES;

export const getUserAccounts = async () => {
  const allAccounts = await API.graphql(graphqlOperation(queries.listAccounts));
  const actualData = allAccounts.data.listAccounts.items.map((acc) => {
    const accData = acc;
    const { iban, creditLimit, sortCodeAccountNumber } = accData.details;
    const iconPath = ICONS.PATH[accData.providerReference.toUpperCase()];
    const additionalText = iban === "Available credit" ? iban : "";
    const additionalValue = iban === "Available credit" ? creditLimit : null;

    return {
      ...accData,
      providerId: accData.providerReference,
      type: accData.type, //need discussion
      imgSrc: require(`assets/images/${iconPath}`), //no data available
      title: accData.accountName,
      accBalance: accData.balance.value,
      currency: CURRENCY_SYMBOL[accData.balance.currency],
      subText: sortCodeAccountNumber,
      connEndDate: "Connection ends in 20 days",
      refreshTime: "Refreshed today, 13: 45",
      additionalText,
      additionalValue,
    };
  });

  return actualData;
};

import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import React from "react";
import { Box } from "rebass";

import { H2, H4 } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
export function ConnectAccountInfo() {
  const { CONNECT_ACCOUNT_INFO_MESSAGES } = MESSAGES;
  const { PARAGRAPH_VARIANT } = APP;
  return (
    <Box>
      <InfoIcon />
      <H2 mt={2} mb={2}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.DATA_SAFE_MESSAGE}
      </H2>
      <H4 mb={3}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.BANK_LEVEL_SECURITY_MESSAGE}
      </H4>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.NATION_WIDE_SECURITY_MESSAGE}
      </Paragraph>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.GOVERNMENT_BACKED_MESSAGE}
      </Paragraph>
      <H4 mb={3}>{CONNECT_ACCOUNT_INFO_MESSAGES.NO_STORE_DATA_MESSAGE}</H4>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.LOGIN_DETAILS_MESSAGE}
      </Paragraph>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.ENCRYPTED_MODE_MESSAGE}
      </Paragraph>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_LARGE_MEDIUM}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.CONNECT_ANY_TIME}
      </Paragraph>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.RENEWAL_TIME_MESSAGE}
      </Paragraph>
      <Paragraph mb={3} variant={PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
        {CONNECT_ACCOUNT_INFO_MESSAGES.DISCONNECT_ANY_TIME}
      </Paragraph>
    </Box>
  );
}

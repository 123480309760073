import React from "react";
import PropTypes from "prop-types";
import { Heading as RebassHeading } from "rebass";

export const Heading = ({ children, variant, ...props }) => (
  <RebassHeading as={variant} variant={variant} {...props}>
    {children}
  </RebassHeading>
);

export const H1 = ({ children, variant, ...props }) => (
  <RebassHeading as="h1" variant="h1" {...props}>
    {children}
  </RebassHeading>
);

export const H2 = ({ children, variant, ...props }) => (
  <RebassHeading as="h2" variant="h2" {...props}>
    {children}
  </RebassHeading>
);

export const H3 = ({ children, variant, ...props }) => (
  <RebassHeading as="h3" variant="h3" {...props}>
    {children}
  </RebassHeading>
);

export const H4 = ({ children, variant, ...props }) => (
  <RebassHeading as="h4" variant="h4" {...props}>
    {children}
  </RebassHeading>
);

Heading.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
};
H1.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
};
H2.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
};
H3.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
};
H4.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
};

import template from "./about.template";

export const AboutComponent = (props) => {
  const events$ = {
    handleClickMeButton: null,
  };

  events$.handleClickMeButton = () => {
    console.log("Button Clicked");
  };

  return template(props, events$);
};

import React from "react";
import { animated, useSpring } from "react-spring";
import { Box, Flex } from "rebass";

import { Steps } from "^atoms/steps/steps.component";
import { STEPS } from "^constants/message.constants";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { preset } from "^style/preset";

import { EmailRegistration } from "./email-registration/email-registration.component";
import { UserRegistration } from "./user-registration/user-registration.component";
import { VerifyEmail } from "./verifiy-email/verify-email.component";

export default (
  props,
  events$,
  page,
  animatedSetPage,
  formValues,
  setFormValues,
  width,
  navigating
) => {
  const passedFunctions = {
    setFormValues,
    animatedSetPage,
    formValues,
    page,
  };
  const style = useSpring({ opacity: !navigating ? 1 : 0 });

  return (
    <Flex flexDirection="column">
      <NavBar
        regScreens
        showArrowLeft
        handleNavigation={events$.handleNavigation}
        bg={preset.colors.greyBG}
        textColor={preset.colors.darkGrey}
        noBurgerIcon
      />
      <Box sx={{ width, height: "100vh" }} bg={preset.colors.greyBG}>
        {page === STEPS.NAME_REGISTER && (
          <animated.div style={style}>
            <Steps text="Your name" stepNumber={page} />
            <UserRegistration {...passedFunctions} />
          </animated.div>
        )}
        {page === STEPS.EMAIL_REGISTER && (
          <animated.div style={style}>
            <Steps text="Your login" stepNumber={page} />
            <EmailRegistration {...passedFunctions} />
          </animated.div>
        )}
        {page === STEPS.VERIFY_EMAIL && (
          <animated.div style={style}>
            <Steps text="Verify your email" stepNumber={page} />
            <VerifyEmail formValues={formValues} />
          </animated.div>
        )}
      </Box>
    </Flex>
  );
};

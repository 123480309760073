import { useNavigate } from "@reach/router";
import { useEffect, useState } from "react";

import template from "./connect-bank-success.template";
import ConnectBankService from "./connect-bank.service";

export const ConnectSuccess = (props) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  useEffect(() => {
    async function fetchAccounts() {
      try {
        const accountsData = await ConnectBankService.fetchAccounts();
        if (
          accountsData &&
          accountsData.data &&
          accountsData.data.listAccounts &&
          accountsData.data.listAccounts.items
        ) {
          setItems(accountsData.data.listAccounts.items);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchAccounts();
  }, []);
  const events$ = {
    handleNavigateToDashboard: null,
    onClickConnectAnotherAccount: null,
  };
  events$.handleNavigateToDashboard = () => {
    navigate("dashboard");
  };
  events$.handleConnectAnotherAccount = () => {
    navigate("connectbank");
  };
  return template(props, events$, items);
};

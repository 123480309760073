import { API, Auth, graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

export default {
  signIn: async (email, password) => {
    let user = await Auth.signIn(email, password);
    // TODO: Please the do data messaging if required by UI
    return user;
  },
  getUserProfile: async (userId) => {
    const profile = await API.graphql(
      graphqlOperation(queries.getDashboardStatus, {
        id: userId,
      })
    );
    // TODO: Please the do data messaging if required by UI
    return profile;
  },

  updateFirstTimeLoginStatus: async (userId) => {
    const profile = await API.graphql({
      query: mutations.updateDashboardStatus,
      variables: {
        input: {
          id: userId,
          firstTimeLogin: true,
        },
      },
    });

    return profile;
  },

  createDashBoard: async (userID) => {
    await API.graphql(
      graphqlOperation(mutations.createDashboardStatus, {
        input: {
          firstTimeLogin: false,
          id: userID,
          onBoarded: false,
          registered: false,
          setupConnectedAccounts: false,
          setupCreditScore: false,
          setupGoal: false,
          setupMonthlyPlan: false,
          setupTimePeriod: false,
          user: userID,
        },
      })
    );
  },
};

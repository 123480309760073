import React from "react";
import { navigate } from "@reach/router";

import { Header } from "^molecules/header/header.component";

import { AccountsListView } from "^organisms/accounts-list-view/accounts-list-view.component";
import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { ReactComponent as AddIcon } from "assets/icons/addFilled.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import preset from "^style//preset";
import { Box } from "rebass";

const { BUTTON_TYPE, LABEL_VARIANT_TYPE, VARIANTS } = APP;
const { colors } = preset;
const { ACCOUNT } = MESSAGES;

export default (accountsData, showAddAccount) => {
  return (
    <>
      <Header
        heading="Accounts"
        greetingName="Tanya"
        actionText="Consectetur Etiam Sem"
        bg={"darkGrey"}
        fg={"white"}
        boxBg={"greyBG"}
      />
      <Box variant={VARIANTS.MY_ACCOUNTS_BOX}>
        {accountsData && (
          <AccountsListView
            accountsData={accountsData}
            showAddNew={true}
            lastUpdated={"Updated just now"} //data comes from api
          />
        )}
        {showAddAccount && (
          <Box bg={colors.greyBG} p={"0 16px"}>
            <ButtonLarge
              icon={AddIcon}
              variant={BUTTON_TYPE.LARGE_SECONDARY_OUTLINE}
              text={ACCOUNT.ADD_BUTTON_TEXT}
              textVariant={LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR_DARK}
              iconPos={"left"}
              buttonSx={{ width: "100%" }}
            />
          </Box>
        )}
        <Box p={"20px 26px"} bg={colors.greyBG}>
          <ButtonLarge
            icon={ArrowRight}
            variant={BUTTON_TYPE.LARGE_PRIMARY_DARK}
            text={ACCOUNT.MANAGE_CONNECTIONS}
            textVariant={LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR_DARK}
            iconPos={"right"}
            buttonSx={{ width: "100%" }}
            onClick={() => {
              navigate("manageaccounts");
            }}
          />
        </Box>
      </Box>
    </>
  );
};

import { Auth } from "aws-amplify";
import { useState } from "react";
import { useWindowSize } from "react-use";
import * as Yup from "yup";

import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";

import template from "./email-registration.template";

export const EmailRegistration = ({
  setFormValues,
  animatedSetPage,
  formValues,
}) => {
  const { width } = useWindowSize();
  const { email, password, confirmPassword } = formValues;

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const initialValues = {
    email: email || "",
    password: password || "",
    confirmPassword: confirmPassword || "",
    agreeTerms: false,
    agreePolicy: false,
    receiveMail: false,
  };
  const { REGISTRATION, VALIDATION } = ERRORS;
  const { REGEX } = APP;

  const onSubmit = async (values, { setSubmitting }) => {
    let AllValues = {
      ...formValues,
      ...values,
    };
    setFormValues(AllValues);
    await signUp(AllValues);
  };
  const signUp = async ({
    firstName,
    lastName,
    password,
    email,
    agreeTerms,
    agreePolicy,
    receiveMail,
  }) => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          "custom:term_of_use": agreeTerms ? "1" : "0",
          "custom:privacy_policy": agreePolicy ? "1" : "0",
          "custom:marketing": receiveMail ? "1" : "0",
        },
        clientMetadata: {
          //TODO: Get this value form environment veriable.
          url: `${window.location.origin}/verifyemail`,
        },
      });

      animatedSetPage(3);
    } catch (error) {
      console.log("error signing up:", error);
    }
  };
  const validationSchema = () =>
    Yup.object({
      email: Yup.string()
        .email(REGISTRATION.EMAIL_NOT_VALID)
        .required(REGISTRATION.PROVIDE_EMAIL),
      password: Yup.string()
        .matches(REGEX.PASSWORD_VALIDATION, VALIDATION.PASSWORD_ERROR_MESSAGE)
        .required(REGISTRATION.PROVIDE_PASSWORD),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], REGISTRATION.PASSWORD_DONT_MATCH)
        .required(REGISTRATION.PROVIDE_CONFIRM_PASSWORD),
      agreeTerms: Yup.boolean().oneOf([true], REGISTRATION.PROVIDE_AGREE_TERMS),
      agreePolicy: Yup.boolean().oneOf(
        [true],
        REGISTRATION.PROVIDE_PRIVACY_POLICY
      ),
    });

  return template(
    initialValues,
    validationSchema,
    onSubmit,
    width,
    isTermsModalOpen,
    setIsTermsModalOpen,
    isPrivacyModalOpen,
    setIsPrivacyModalOpen
  );
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { animated, config, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { makeStyles } from "@material-ui/styles";

const defaultHeight = "1px";

const useStyles = makeStyles({
  accordion: {
    overflow: "hidden",
    position: "relative",
  },
});

export const ExpandCollapse = ({ children, isOpen, ...props }) => {
  const classes = useStyles();
  const [ref, { height }] = useMeasure();
  const [contentHeight, setContentHeight] = useState(defaultHeight);

  const expand = useSpring({
    config: config.gentle,
    height: isOpen ? `${contentHeight}px` : defaultHeight,
  });

  useEffect(() => {
    setContentHeight(height);
    window.addEventListener("resize", setContentHeight(height));
    return window.removeEventListener("resize", setContentHeight(height));
  }, [height]);

  return (
    <animated.div className={classes.accordion} style={expand} {...props}>
      <Box ref={ref}>{children}</Box>
    </animated.div>
  );
};

ExpandCollapse.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

import PropTypes from "prop-types";
import React from "react";
import { useWindowSize } from "react-use";
import { Box, Flex } from "rebass";

import { H2 } from "^atoms/typography/heading.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

export const Steps = ({
  stepNumber,
  text,
  maxValue = 3,
  labelColor,
  headingColor,
}) => {
  const { width } = useWindowSize();
  const { LABEL_VARIANT_TYPE } = APP;
  return (
    <Box sx={{ width: width }}>
      <Flex flexDirection="column" pl={3} pr={3} pt={3} pb={2}>
        <Flex justifyContent="flex-start" pb={2}>
          <Label
            variant={LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR}
            sx={{ color: labelColor }}
          >
            {`Step ${stepNumber} of ${maxValue}`}
          </Label>
        </Flex>
        <Flex justifyContent="flex-start" flexGrow={1}>
          <H2 color="text" sx={{ color: headingColor }}>
            {text}
          </H2>
        </Flex>
      </Flex>
    </Box>
  );
};

Steps.propTypes = {
  stepNumber: PropTypes.number,
  text: PropTypes.string,
  maxValue: PropTypes.number,
  labelColor: PropTypes.string,
  headingColor: PropTypes.string,
};

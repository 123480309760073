import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import preset from "style/preset";

import { H2 } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { MESSAGES } from "^constants/message.constants";
import { UTILS } from "utilities/common";
import { APP } from "^constants/app.constant";

import { ReactComponent as notificationWarning } from "assets/icons/notificationWarning.svg";

export const DisconnectAccountModal = (props) => {
  return (
    <Flex flexDirection="column">
      <Flex sx={{ marginTop: "2px", marginLeft: "2px" }}>
        <Box
          as={notificationWarning}
          width={24}
          height={24}
          fill={preset.colors.redBase}
        />
      </Flex>

      <Flex sx={{ marginTop: "10px" }}>
        <H2>
          {UTILS.placeholderFormatter(
            MESSAGES.ACCOUNT.DISCONNECT_MODAL_HEADER,
            props.provider
          )}
        </H2>
      </Flex>

      <Flex
        mt={2}
        mb={2}
        sx={{ borderRadius: 0 }}
        bg={props.bg}
        flexGrow={1}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Paragraph variant={APP.PARAGRAPH_VARIANT.PARA_MEDIUM_REGULAR}>
          {MESSAGES.ACCOUNT.DISCONNECT_MODAL_MESSAGE}
        </Paragraph>
      </Flex>
    </Flex>
  );
};

DisconnectAccountModal.propTypes = {
  provider: PropTypes.string,
};

import React from "react";
import { Box, Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { MESSAGES } from "^constants/message.constants";
import { PageHeader } from "^molecules/page-header/page-header.component";
import { preset } from "^style//preset";

export default (events$, width, loading) => {
  return (
    <Box sx={{ width }} bg={preset.colors.greyBG}>
      {!loading && (
        <Flex flexDirection="column" alignItems="center">
          <Flex>
            <PageHeader
              description={MESSAGES.REGISTRATION.CONNECT_ACCOUNT}
              heading={MESSAGES.REGISTRATION.USER_EMAIL_IS_VERIFIED}
              showLogo
            />
          </Flex>
          <Flex pt={4}>
            <ButtonLarge
              onClick={events$.onContinue}
              text="Continue"
              variant="largePrimaryDark"
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

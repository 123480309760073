import { Auth } from "aws-amplify";

import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";

import template from "./email-verification.template";

export const EmailVerification = ({
  steps,
  setCurrentStep,
  showResetButton,
  setShowResetButton,
  emailAddress,
  setEmailAddress,
}) => {
  //Start: variable declaration
  const events$ = {
    handleNavigation: null,
    handleSubmission: null,
  };
  //End: variable declaration

  //Start: event handler definitions
  events$.handleValidation = (values) => {
    const errors = {};
    if (!APP.REGEX.EMAIL_VALIDATION.test(values.emailAddress))
      errors.emailAddress = ERRORS.VALIDATION.EMAIL_ERROR_MESSAGE;

    if (errors && errors.emailAddress) setShowResetButton(false);
    else setShowResetButton(true);
    return errors;
  };

  events$.handleSubmission = async (values, actions) => {
    try {
      var username = values.emailAddress;
      var clientMetadata = { url: `${window.location.origin}/resetpassword` };

      await Auth.forgotPassword(username, clientMetadata);
      setCurrentStep(steps.CHECK_EMAIL);
    } catch (error) {
      //TODO: later remove consoles and implement functionality if Auth.forgotPassword api fails.
      console.log("api got failed ", error);
    }
    setEmailAddress(values.emailAddress);
    actions.setSubmitting(false);
  };
  //End: event handler definitions

  return template(events$, showResetButton, emailAddress);
};

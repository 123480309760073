import {
  configureStore,
  createAction,
  createReducer,
  combineReducers,
  createAsyncThunk,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { appReducer } from "reducers/appReducer";
import { profileReducer } from "reducers/profileReducer";
import { accountReducer } from "reducers/accountReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["app", "account"], //Things u want to persist
  blacklist: [], //Things u dont
};

const reducers = combineReducers({
  app: appReducer,
  profile: profileReducer,
  account: accountReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

import { APP } from "^constants/app.constant";
import { UTILS } from "^utilities/common";

const {
  ACCOUNT_CONNECTION_LIST_TYPE: LIST_VARIANTS,
  ACCOUNT_CONNECTION_CARD_TYPE: CARD_VARIANTS,
} = APP;

export const getListData = (accounts, handleRenew, handleDisconnect) => {
  const uniqueProviders = accounts
    .map((account) => account.providerId)
    .filter(UTILS.getUnique)
    .sort();
  return uniqueProviders.map((providerId) => {
    const accountItems = accounts
      .filter((acc) => acc.providerId === providerId)
      .sort((a, b) => (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1));
    const {
      refreshTime,
      providerName: title,
      imgSrc,
      connEndDate,
    } = accountItems[0];
    return {
      refreshTime,
      title,
      imgSrc,
      connEndDate,
      accountItems,
      primaryButton: {
        text: "Renew",
        onClick: (cardData) => handleRenew(title, imgSrc, cardData),
      },
      secondaryButton: {
        text: "Disconnect",
        onClick: (cardData) => handleDisconnect(title, cardData),
      },
    };
  });
};

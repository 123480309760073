import PropTypes from "prop-types";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box, Button as RebassButton, Flex } from "rebass";

import { APP } from "^constants/app.constant";

export const IconButton = ({ fg, variant, disabled, icon, ...props }) => {
  const [clicked, set] = useState(false);
  const { scale } = useSpring({ scale: clicked ? 0.6 : 1 });
  const AnimatedButton = animated(RebassButton);

  // const animate = () => {
  //   set(true);
  //   setTimeout(() => {
  //     set(false);
  //   }, 100);
  // };

  return (
    <AnimatedButton
      m={0}
      p={0}
      {...props}
      variant={variant}
      style={{
        userSelect: "none",
        opacity: !disabled ? 1 : 0.5,
        pointerEvents: !disabled ? true : "none",
        transform: scale.interpolate((s) => `scale(${s})`),
      }}
    >
      <Flex alignItems="center">
        <Box as={icon} fill={fg} />
      </Flex>
    </AnimatedButton>
  );
};

IconButton.propTypes = {
  fg: PropTypes.string,
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    APP.ICON_BUTTON.LARGE_ICON_BUTTON,
    APP.ICON_BUTTON.MEDIUM_ICON_BUTTON,
    APP.ICON_BUTTON.SMALL_ICON_BUTTON,
  ]),
};

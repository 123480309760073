export const ERRORS = {
  VALIDATION: {
    EMAIL_ERROR_MESSAGE: "The email address has not been entered correctly",
    PASSWORD_ERROR_MESSAGE:
      "Your password must include at least 8 characters, 1 capitalised letter, 1 lower case letter and 1 number",
    CONFIRM_PASSWORD_ERROR_MESSAGE: "Your passwords don’t match",
  },
  REGISTRATION: {
    PROVIDE_FIRST_NAME: "Please provide your first name",
    PROVIDE_LAST_NAME: "Please provide your last name",
    PROVIDE_EMAIL: "Please provide your Email",
    PROVIDE_PASSWORD: "Please provide your Password",
    PROVIDE_CONFIRM_PASSWORD: "Please provide your Confirm password",
    PASSWORD_DONT_MATCH: "your passwords don't match",
    PROVIDE_AGREE_TERMS: "Please read and agree to the terms of use",
    PROVIDE_PRIVACY_POLICY: "Please read and agree to the privacy policy",
    EMAIL_NOT_VALID: "Your email address is not valid",
  },
  LOGIN: {
    INVALID_PASSWORD: "Your password has not been entered correctly",
    INVALID_EMAIL: "Your email address has not been entered correctly",
  },
  CONNECT_BANK: {
    CONNECT_FAILURE_MESSAGE: "Your connection was unsuccessful",
    TRY_AGAIN_MESSAGE: "Try again now",
    REASON_MESSAGE: "Reason for unsuccessful connection",
  },
  CONNECTIVITY: {},
};

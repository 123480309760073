import { useEffect } from "react";
import template from "./renew-error.template";

export const RenewError = (props) => {

  useEffect(() => {
  }, []);

  return template(props);
};

import React from "react";
import PropTypes from "prop-types";

import { Flex } from "rebass";

import { AccountList } from "^molecules/account-list/account-list.component";
import { Label } from "^atoms/typography/label.component";
import { getListData } from "./accounts-list-view.service";

export const AccountsListView = ({ accountsData, lastUpdated, ...props }) => {
  const allListData = getListData(accountsData);
  return (
    <Flex justifyContent="center" flexDirection="column" {...props}>
      {accountsData && accountsData.length > 0 && (
        <Flex bg={"greyBG"} justifyContent="center">
          <Label {...props} variant="labelTagLight">
            {lastUpdated}
          </Label>
        </Flex>
      )}
      {allListData.map((listData, i) => (
        <AccountList key={`accList${i}`} {...listData} />
      ))}
    </Flex>
  );
};

AccountsListView.propTypes = {
  accountsData: PropTypes.array,
  lastUpdated: PropTypes.string,
};

import { navigate } from "@reach/router";
import { ReactComponent as OnboardGlyph } from "assets/glyphs/onboardGlyph.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Flex, Text } from "rebass";

import { Button } from "^atoms/buttons/button.component";
import { H2, H3, H4 } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { TextLink } from "^atoms/typography/text-link.component";
import { Modal } from "^molecules/modal/modal.component";

const raddi = 20;

export const Onboard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.app);
  const givenname = user ? user.given_name : "Tanya";

  const onConnectButtonClick = () => {
    navigate("/connectinfo");
  };

  const onModalButtonClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Flex
        ml={3}
        mr={3}
        mb={3}
        mt={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg="white"
        fg="white"
        flexGrow={1}
        sx={{
          borderRadius: raddi,
          textAlign: "center",
          boxShadow: "4px 4px 16px -5px rgba(0,0,0,0.16)",
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Box as={OnboardGlyph} m={3} />
          <H2 m={2}>Welcome, {givenname}</H2>
          <Paragraph variant="paraMediumRegular" m={2}>
            Do more with your money by connecting your bank accounts to [The
            App]
          </Paragraph>
          <Button
            m={3}
            variant="largePrimaryDark"
            text="Connect your accounts"
            icon={ArrowRight}
            iconPos="right"
            onClick={onConnectButtonClick}
          />
          <TextLink variant="textLinkSmall" onClick={onModalButtonClick}>
            How secure is this?
          </TextLink>
        </Flex>
      </Flex>
      <Modal
        bg="white"
        fg="darkGrey"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dismissText="Got it!"
        content={
          <Box>
            <InfoIcon />
            <H3 mt={2} mb={2}>
              We care about keeping your data safe
            </H3>
            <H4 mb={3}>We use bank-level security</H4>
            <Paragraph mb={3} variant="paraMediumRegular">
              We’re backed by Nationwide Building Society and registered with
              the Financial Conduct Authority
            </Paragraph>
            <Paragraph mb={3} variant="paraMediumRegular">
              We use regulated government-backed technology to retrieve
              information about your accounts
            </Paragraph>
            <H4 mb={3}>We won’t store your data</H4>
            <Paragraph mb={3} variant="paraMediumRegular">
              Your bank login details will be safe with us, as we never store
              data
            </Paragraph>
          </Box>
        }
      />
    </>
  );
};

Onboard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  modalAction: PropTypes.string,
  modalContent: PropTypes.string,
};

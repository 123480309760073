import PropTypes from "prop-types";
import React from "react";
import { Box, Button as RebassButton, Flex } from "rebass";
import { Icon } from "^atoms/icon/icon.component";
import { preset } from "style/preset";

import { APP } from "^constants/app.constant";

export const ButtonMedium = ({
  variant,
  text,
  disabled,
  icon,
  iconPos,
  bgChange,
  ...props
}) => (
  <RebassButton
    {...props}
    variant={variant}
    sx={{
      opacity: !disabled ? 1 : 0.5,
      pointerEvents: !disabled ? true : "none",
      backgroundColor: bgChange ? preset.colors.greyBG : preset.colors.white,
    }}
  >
    <Flex alignItems="center">
      {icon && iconPos === "left" && <Icon icon={icon} iconPos={iconPos} />}
      <ButtonText text={text} />
      {icon && iconPos === "right" && <Icon icon={icon} iconPos={iconPos} />}
    </Flex>
  </RebassButton>
);

const ButtonText = ({ text }) => <Box>{text}</Box>;

ButtonMedium.propTypes = {
  icon: PropTypes.any,
  iconPos: PropTypes.oneOf(["right", "left"]),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    APP.BUTTON_TYPE.MEDIUM_PRIMARY_DARK,
    APP.BUTTON_TYPE.MEDIUM_PRIMARY_LIGHT,
    APP.BUTTON_TYPE.MEDIUM_SECONDARY_GREY,
    APP.BUTTON_TYPE.MEDIUM_SECONDARY_OUTLINE,
  ]),
  bgChange: PropTypes.bool,
};

import React, { useState } from "react";
import { Text, Box, Flex } from "rebass";

import { Layout } from "^organisms/layout/layout.component";
import { Modal } from "^molecules/modal/modal.component";
import { ButtonLarge } from "^atoms/buttons/button.large.component";

export const Legal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        content={ModalContent}
      />
      <Flex alignItems="center" p={3}>
        <Flex sx={{ flexGrow: 1 }} justifyContent="center">
          <ButtonLarge
            text="Open modal"
            variant="largePrimaryDark"
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
        </Flex>
      </Flex>
    </>
  );
};

const ModalContent = () => (
  <Box>
    <Text variant="body">
      Maecenas sed diam eget risus varius blandit sit amet non magna. Nulla
      vitae elit libero, a pharetra augue. Fusce dapibus, tellus ac cursus
      commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet
      risus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
    </Text>
  </Box>
);

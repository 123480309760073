import React from "react";

export default (events$) => {
  return (
    <>
      <button value="hello!" onClick={() => events$.handleClearMockData()}>
        Clear mock data
      </button>

      <button value="hello!" onClick={() => events$.handlePopulateMockData()}>
        Build mock data
      </button>
    </>
  );
};

import { Link } from "@reach/router";
import React from "react";
import { Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { NotificationMessage } from "^atoms/notification-message/notification-message.component";
import { CTA } from "^atoms/typography/cta.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { UTILS } from "^utilities/common";
import { preset } from "^style/preset";

export default (props) => {
  return (
    <Flex
      flexDirection="column"
      sx={{ height: "100vh" }}
      bg={preset.colors.darkGrey}
      mb={1}
      ml={3}
      mr={3}
    >
      <Flex mt={4}>
        <NotificationMessage
          variant={APP.NOTIFICATION_MESSAGE.DARK_ERROR}
          headerMessage={UTILS.placeholderFormatter(
            MESSAGES.RENEW_ERROR_PAGE.HEADER,
            MESSAGES.APP_NAME,
            props.renewStatus.providerName
          )}
          message={UTILS.placeholderFormatter(
            MESSAGES.RENEW_ERROR_PAGE.MESSAGE_ONE,
            MESSAGES.APP_NAME,
            props.renewStatus.providerName
          )}
        />
      </Flex>

      <Flex mt={4} mb={2}>
        <Label
          variant={APP.LABEL_VARIANT_TYPE.LABEL_MEDIUM_REGULAR}
          color="white"
          sx={{ fontWeight: 400, textAlign: "center" }}
        >
          {MESSAGES.RENEW_ERROR_PAGE.MESSAGE_TWO}
        </Label>
      </Flex>

      <Flex justifyContent="center" mt={3} mb={1}>
        <Link to="/accounts" style={preset.styles.link}>
          <ButtonLarge
            variant={APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
            text={MESSAGES.RENEW_ERROR_PAGE.PRIMARY_BUTTON_TEXT}
          />
        </Link>
      </Flex>

      <Flex justifyContent="center" mt={3}>
        <CTA variant={APP.CTA_VARIANT.CTA_SMALL} color="white">
          {MESSAGES.RENEW_ERROR_PAGE.SECONDARY_BUTTON_TEXT}
        </CTA>
      </Flex>
    </Flex>
  );
};

import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass";

import { H2 } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { Modal } from "^molecules/modal/modal.component";

export const TermsPrivacyModal = ({
  header,
  content,
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      bg={"white"}
      hideFooter
      content={
        <Box>
          <H2>{header}</H2>
          <Paragraph variant="paraMediumRegular" mt={10}>
            {content}
          </Paragraph>
        </Box>
      }
    />
  );
};

TermsPrivacyModal.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

export default {
  items: [
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Amex Savings",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 90011,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "50161331926818",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc25",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Amex",
      providerReference: "amex",
      type: "savings",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Monzo Cash",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 600021,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "90163192681",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc21",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Monzo",
      providerReference: "monzo",
      type: "cash_current",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Monzo Card",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 300021,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "331926816",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Monzo",
      providerReference: "monzo",
      type: "card",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "HSBC Savings",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 170011,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "7613319265489",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc27",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "HSBC",
      providerReference: "hsbc",
      type: "savings",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Amex Card",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 190011,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "601331921",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc24",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Amex",
      providerReference: "amex",
      type: "card",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Hali Card",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 10710,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "192681556",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc32",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Hali Fax",
      providerReference: "halifax",
      type: "card",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Hali Saving",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 19710,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "xxxxxxxx1926819",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc33",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Hali Fax",
      providerReference: "halifax",
      type: "savings",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Flexplus Savings",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 370011,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "489313319268178",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc26",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Flexplus",
      providerReference: "flexplus",
      type: "savings",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Nationwide Flexplus",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 290021,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "20141331921986",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc23",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Flex plus",
      providerReference: "flexplus",
      type: "card",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Monzo Savings",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 990021,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "1017788133192681",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc22",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Monzo",
      providerReference: "monzo",
      type: "savings",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "HSBC Current",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 18910,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "5616133192",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc29",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "HSBC",
      providerReference: "hsbc",
      type: "cash_current",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "Hali Fax Current",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 10910,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "70161331926334455",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc31",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "Hali Fax",
      providerReference: "halifax",
      type: "cash_current",
    },
    {
      accountHolderName: "Mr Rob Ike",
      accountName: "HSBC Card",
      accountReference: "3201",
      balance: {
        currency: "GBP",
        value: 171021,
      },
      connectionId:
        "049c10ab871e8d60aa891c0ae368322d:639cf079-a585-4852-8b4d-1ebd17f4d2cb",
      dateAdded: "2018-07-10T11:39:44+00:00",
      dateModified: "2018-07-10T11:39:44+00:00",
      details: {
        AER: 1.3,
        APR: 13.1,
        creditLimit: 150000,
        endDate: "2020-01-01",
        fixedDate: "2019-01-01",
        iban: "GB2960161331926819",
        interestFreePeriod: 12,
        interestType: "fixed",
        linkedProperty: "ac9bd177-d01e-449c-9f29-d3656d2edc2e",
        monthlyRepayment: 60000,
        overdraftLimit: 150000,
        postcode: "bs1 1aa",
        runningCost: 20000,
        runningCostPeriod: "month",
        sortCodeAccountNumber: "5601613319268443",
        term: 13,
        yearlyAppreciation: -10,
      },
      id: "ac9bd177-d01e-449c-9f29-d3656d2edc28",
      providerId: "049c10ab871e8d60aa891c0ae368322d",
      providerName: "HSBC",
      providerReference: "hsbc",
      type: "card",
    },
  ],
};

import { Link } from "@reach/router";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { NotificationMessage } from "^atoms/notification-message/notification-message.component";
import { CTA } from "^atoms/typography/cta.component";
import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";
import { MESSAGES } from "^constants/message.constants";

export default (
  props,
  { handleNavigateDiffAccount, handleNavigateToDashboard }
) => {
  const { COMMON, CONNECT_ACCOUNT_MESSAGES } = MESSAGES;
  const { CONNECT_BANK } = ERRORS;

  return (
    <Box>
      <Flex
        flexDirection="column"
        sx={{ height: "100vh" }}
        bg={preset.colors.darkGrey}
      >
        <Flex mt={4}>
          <NotificationMessage
            variant={APP.NOTIFICATION_MESSAGE.DARK_ERROR}
            headerMessage={CONNECT_BANK.CONNECT_FAILURE_MESSAGE}
            message={CONNECT_BANK.REASON_MESSAGE}
          />
        </Flex>

        <Flex justifyContent="center" mt={3} mb={1}>
          <Link to="/accounts" style={preset.styles.link}>
            <ButtonLarge
              variant={APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
              text={CONNECT_BANK.TRY_AGAIN_MESSAGE}
            />
          </Link>
        </Flex>
        <Flex justifyContent="center" mt={3}>
          <CTA
            variant={APP.CTA_VARIANT.CTA_SMALL_LETTERS}
            color={preset.colors.white}
            onClick={handleNavigateDiffAccount}
          >
            {CONNECT_ACCOUNT_MESSAGES.CONNECT_DIFFERENT_ACCOUNT}
          </CTA>
        </Flex>

        <Flex justifyContent="center" mt={3}>
          <CTA
            variant={APP.CTA_VARIANT.CTA_SMALL_LETTERS}
            color={preset.colors.white}
            onClick={handleNavigateToDashboard}
          >
            {COMMON.GOTO_MY_DASHBOARD}
          </CTA>
        </Flex>
      </Flex>
    </Box>
  );
};

import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

import accounts from "../../../../mock-data/accounts";
import banks from "../../../../mock-data/banks";
import dashboard from "../../../../mock-data/dashboard";

export const TYPE = {
  ACCOUNT: "ACCOUNT",
  DASHBOARD_STATUS: "DASHBOARD STATUS",
  BANK: "BANK",
};

const Service = {
  getTypesListCommand: (type) => {
    switch (type) {
      case TYPE.ACCOUNT: {
        return queries.listAccounts;
      }
      case TYPE.BANK: {
        return queries.listBanks;
      }
      case TYPE.DASHBOARD_STATUS: {
        return queries.listDashboardStatuss;
      }
    }
  },

  getClearTypesCommand: (type) => {
    switch (type) {
      case TYPE.ACCOUNT: {
        return mutations.deleteAccount;
      }
      case TYPE.BANK: {
        return mutations.deleteBank;
      }
      case TYPE.DASHBOARD_STATUS: {
        return mutations.deleteDashboardStatus;
      }
    }
  },

  getCreateTypeCommand: (type) => {
    switch (type) {
      case TYPE.ACCOUNT: {
        return mutations.createAccount;
      }
      case TYPE.BANK: {
        return mutations.createBank;
      }
      case TYPE.DASHBOARD_STATUS: {
        return mutations.createDashboardStatus;
      }
    }
  },
  clearType: async (type) => {
    await API.graphql(graphqlOperation(Service.getTypesListCommand(type))).then(
      (data) => {
        const listPointer =
          type === TYPE.DASHBOARD_STATUS
            ? data.data.listDashboardStatuss
            : type === TYPE.ACCOUNT
            ? data.data.listAccounts
            : data.data.listBanks;
        listPointer.items.map((i) => {
          API.graphql(
            graphqlOperation(Service.getClearTypesCommand(type), {
              input: {
                id: i.id,
              },
            })
          ).then(() => {
            console.log("Deleted", i.id);
          });
        });
      }
    );
  },
  populateType: async (type) => {
    const mockDataPointer =
      type === TYPE.ACCOUNT
        ? accounts
        : type === TYPE.DASHBOARD_STATUS
        ? dashboard
        : banks;

    mockDataPointer.items.map((i) => {
      API.graphql(
        graphqlOperation(Service.getCreateTypeCommand(type), {
          input: i,
        })
      );
    });
  },
};

export default Service;

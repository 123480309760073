import service from "./mock-handler.service";
import { TYPE } from "./mock-handler.service";
import template from "./mock-handler.template";

export const MockHandlerComponent = () => {
  const events$ = {
    handleClearMockData: null,
    handlePopulateMockData: null,
  };

  events$.handleClearMockData = () => {
    service.clearType(TYPE.BANK).then(() => {
      console.log("Cleared Banks");
    });
    service.clearType(TYPE.ACCOUNT).then(() => {
      console.log("Cleared Accounts");
    });
    service.clearType(TYPE.DASHBOARD_STATUS).then(() => {
      console.log("Cleared Dashboards");
    });
  };

  events$.handlePopulateMockData = () => {
    service.populateType(TYPE.BANK).then(() => {
      console.log("Populate Bank");
    });
    service.populateType(TYPE.ACCOUNT).then(() => {
      console.log("Populate Accounts");
    });
    service.populateType(TYPE.DASHBOARD_STATUS).then(() => {
      console.log("Populate Dashboard");
    });
  };

  return template(events$);
};

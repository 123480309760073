import React from "react";
import { Box, Flex } from "rebass";

import { Paragraph } from "^atoms/typography/paragraph.component";
import { MESSAGES } from "^constants/message.constants";

export default (formValues) => {
  const { REGISTRATION } = MESSAGES;
  return (
    <Box>
      <Flex
        justifyContent="center"
        flexDirection="column"
        pl={2}
        sx={{ margin: "0 11px" }}
      >
        <Box pb={3}>
          <Paragraph variant="paraMediumRegular">
            {REGISTRATION.VERIFICATION_MAIL_MESSAGE} <b>{formValues.email}</b>
          </Paragraph>
        </Box>
        <Box pb={3}>
          <Paragraph variant="paraMediumRegular">
            {REGISTRATION.ACCOUNT_VERIFICATION_MESSAGE}
          </Paragraph>
        </Box>
      </Flex>
    </Box>
  );
};

import { Form, Formik } from "formik";
import React from "react";
import { Box, Flex, Link } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { ERRORS } from "^constants/errors.constant";
import { MESSAGES } from "^constants/message.constants";
import { FormCheck } from "^molecules/form-check/form-check.component";
import { FormText } from "^molecules/form-text/form-text.componet";

import { TermsPrivacyModal } from "../terms-privacy-modal/terms-privacy-modal.component";

//TODO: Remove the value in constants.
export default (
  initialValues,
  validationSchema,
  onSubmit,
  width,
  isTermsModalOpen,
  setIsTermsModalOpen,
  isPrivacyModalOpen,
  setIsPrivacyModalOpen
) => {
  const { REGISTRATION } = MESSAGES;
  const { VALIDATION } = ERRORS;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { email, password, agreeTerms, agreePolicy } = props.values;
          const { isSubmitting, isValid } = props;
          const Validate =
            email && password && agreeTerms && agreePolicy && isValid;
          return (
            <Box height="100vh">
              <Form>
                <Box p={3}>
                  <FormText
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="example@email.com"
                  />
                </Box>

                <Box p={3}>
                  <FormText
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    passIndication
                    message={VALIDATION.PASSWORD_ERROR_MESSAGE}
                  />
                </Box>
                <Box p={3}>
                  <FormText
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label="Confirm password"
                  />
                </Box>
                <FormCheck id="agreeTerms" name="agreeTerms">
                  <span>
                    {MESSAGES.REGISTRATION.AGREE_MESSAGE}
                    <Link
                      color="black"
                      onClick={() => setIsTermsModalOpen(!isTermsModalOpen)}
                    >
                      <u>{MESSAGES.REGISTRATION.TERMS_LINK}</u>
                    </Link>
                  </span>
                </FormCheck>
                <FormCheck id="agreePolicy" name="agreePolicy">
                  <span>
                    {MESSAGES.REGISTRATION.AGREE_MESSAGE}
                    <Link
                      color="black"
                      onClick={() => setIsPrivacyModalOpen(!isPrivacyModalOpen)}
                    >
                      <u>{MESSAGES.REGISTRATION.PRIVACY_LINK}</u>
                    </Link>
                  </span>
                </FormCheck>
                <Flex justifyContent="center">
                  <FormCheck id="recievemail" name="recievemail">
                    <span style={{ paddingLeft: "7px", display: "flex" }}>
                      {REGISTRATION.RECEIVE_MAIL_MESSAGE}
                    </span>
                  </FormCheck>
                </Flex>

                <Flex justifyContent="center">
                  <ButtonLarge
                    text="Next"
                    disabled={!Validate || isSubmitting}
                    variant="largePrimaryDark"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      padding: "14px",
                      width,
                    }}
                  />
                </Flex>
              </Form>
            </Box>
          );
        }}
      </Formik>

      {isTermsModalOpen && (
        <TermsPrivacyModal
          header={MESSAGES.TERM_PRIVACY.HEADER_TERMS_MODAL}
          content={MESSAGES.TERM_PRIVACY.CONTENT_TERMS_MODAL}
          isModalOpen={isTermsModalOpen}
          setIsModalOpen={setIsTermsModalOpen}
        />
      )}
      {isPrivacyModalOpen && (
        <TermsPrivacyModal
          header={MESSAGES.TERM_PRIVACY.HEADER_PRIVACY_MODAL}
          content={MESSAGES.TERM_PRIVACY.CONTENT_PRIVACY_MODAL}
          isModalOpen={isPrivacyModalOpen}
          setIsModalOpen={setIsPrivacyModalOpen}
        />
      )}
    </>
  );
};

import { ReactComponent as NavRight } from "assets/icons/navRight.svg";
import PropTypes from "prop-types";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";
import { UTILS } from "utilities/common";

import { Icon } from "^atoms/icon/icon.component";
import { SquareImage } from "^atoms/image/image.square.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

const { radii, sizes, accountCardVariants, colors } = preset;
const {
  CURRENCY_SYMBOL,
  ACCOUNT_CARD_TYPE,
  LABEL_VARIANT_TYPE,
  IMAGE_TYPE,
} = APP;
const { LABEL_SMALL_REGULAR_LITE, LABEL_MEDIUM_MEDIUM } = LABEL_VARIANT_TYPE;
const { getNumberData } = UTILS;

export const cardContent = (leftContent, rightContent) => {
  return (
    <Flex alignItems="center">
      <Box width={rightContent ? 0.7 : 1}>{leftContent}</Box>
      {rightContent && (
        <Flex
          width={0.5}
          mx="auto"
          sx={{
            justifyContent: "flex-end",
          }}
        >
          {rightContent}
        </Flex>
      )}
    </Flex>
  );
};

export const AccountCard = ({
  imgSrc,
  title,
  accBalance,
  currency = CURRENCY_SYMBOL.GBP,
  subText,
  additionalText,
  additionalValue,
  variant = ACCOUNT_CARD_TYPE.LIGHT,
  canNavigate,
  ...props
}) => {
  const cardTheme = accountCardVariants[variant];
  const {
    textColor,
    bgColor,
    borderStyle,
    borderColor,
    isCurvyBox,
    padding,
  } = cardTheme;
  const radius = isCurvyBox ? radii.curvedBox : radii.sharpBox;
  const numData = getNumberData(accBalance);
  const addNumData = getNumberData(additionalValue || 0);

  return (
    <Box
      {...props}
      color={colors[textColor]}
      bg={colors[bgColor]}
      sx={{
        borderRadius: radius,
        border: borderStyle + (borderColor ? colors[borderColor] : "") || "",
        padding,
      }}
    >
      <Flex>
        {imgSrc && (
          <Flex width={"12%"} sx={{ margin: "auto 0" }}>
            <SquareImage
              isCurved
              imgSrc={imgSrc}
              imgType={IMAGE_TYPE.OTHERS}
              size={sizes.cardImage}
              title={title}
            />
          </Flex>
        )}
        <Box width={"88%"} sx={{ margin: "auto 0 auto 7px" }}>
          {cardContent(
            <>
              <Label variant={LABEL_MEDIUM_MEDIUM}>{title}</Label>
              {subText && (
                <Label variant={LABEL_SMALL_REGULAR_LITE}>{subText}</Label>
              )}
            </>,
            <>
              {accBalance && (
                <Label variant={LABEL_MEDIUM_MEDIUM}>
                  {(numData.isNegative ? "-" : "") + currency + numData.value}
                </Label>
              )}
              {canNavigate && (
                <Icon
                  icon={NavRight}
                  iconPos={"right"}
                  sx={{ width: "100%", ml: "75%" }}
                />
              )}
            </>
          )}
          {additionalText &&
            additionalValue &&
            cardContent(
              <>
                <Label variant={LABEL_SMALL_REGULAR_LITE}>
                  {additionalText}
                </Label>
              </>,
              <Label variant={LABEL_SMALL_REGULAR_LITE}>
                {(addNumData.isNegative ? "-" : "") +
                  currency +
                  addNumData.value}
              </Label>
            )}
        </Box>
      </Flex>
    </Box>
  );
};

AccountCard.propTypes = {
  variant: PropTypes.oneOf([
    ACCOUNT_CARD_TYPE.DARK,
    ACCOUNT_CARD_TYPE.LIGHT,
    ACCOUNT_CARD_TYPE.BLUE,
    ACCOUNT_CARD_TYPE.TRANSPARENT,
  ]),
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  accBalance: PropTypes.number,
  canNavigate: PropTypes.bool,
  currency: PropTypes.oneOf([CURRENCY_SYMBOL.GBP, CURRENCY_SYMBOL.USD]),
  subText: PropTypes.string,
  additionalText: PropTypes.string,
  additionalValue: PropTypes.number,
};

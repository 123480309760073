export const ICONS = {
  PATH: {
    AMEX: "AmexLogo.svg",
    MONZO: "MonzoLogo.svg",
    FLEXPLUS: "FlexPlusLogo.svg",
    HALIFAX: "HalifaxLogo.svg",
    INSTAAC: "InstaAcLogo.png",
    HSBC: "HSBCLogo.svg",
  },
};

import PropTypes from "prop-types";
import { useEffect } from "react";

import template from "./verify-email.template";

export function VerifyEmail(props) {
  //Start: variable declaration
  const { formValues } = props;
  //End: variable declaration
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return template(formValues);
}

VerifyEmail.prototype = {
  formValues: PropTypes.object,
};

import { createAction, createReducer } from "@reduxjs/toolkit";

export const setProfileAction = createAction("app/profile");

export const profileReducer = createReducer(
  {
    profile: null,
  },
  {
    [setProfileAction]: (state, action) =>
      (state = { ...state, profile: action.payload }),
  }
);

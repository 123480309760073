import { useWindowSize } from "react-use";
import * as Yup from "yup";

import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";
import { MESSAGES, STEPS } from "^constants/message.constants";

import template from "./user-registration.template";

export const UserRegistration = ({
  setFormValues,
  animatedSetPage,
  formValues,
}) => {
  const { width } = useWindowSize();
  const { firstName = null, lastName = null } = formValues;

  let initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setFormValues((state) => ({ ...state, ...values }));
    animatedSetPage(STEPS.EMAIL_REGISTER);
  };

  const validationSchema = () =>
    Yup.object({
      firstName: Yup.string()
        .matches(APP.REGEX.ALPHABET_ONLY, MESSAGES.COMMON.ALPHABET_ONLY)
        .required(ERRORS.REGISTRATION.PROVIDE_FIRST_NAME),
      lastName: Yup.string()
        .matches(APP.REGEX.ALPHABET_ONLY, MESSAGES.COMMON.ALPHABET_ONLY)
        .required(ERRORS.REGISTRATION.PROVIDE_LAST_NAME),
    });

  return template(initialValues, validationSchema, onSubmit, width);
};

import "modern-css-reset";

import { createHistory, LocationProvider } from "@reach/router";
import Amplify from "aws-amplify";
import { ThemeProvider } from "emotion-theming";
import React from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { NotMobile } from "^molecules/not-mobile/not-mobile.component";

import App from "./app/app";
import { persistor, store } from "./app/store";
import { globalFonts } from "./assets/fonts/graphik/fonts";
import aws_exports from "./aws-exports";
import { preset } from "./style/preset";

globalFonts();

if (!isMobile && !process.env.REACT_APP_E2E_MODE) {
  ReactDOM.render(
    <ThemeProvider theme={preset}>
      <NotMobile />
    </ThemeProvider>,
    document.getElementById("root")
  );
} else {
  Amplify.configure(aws_exports);
  const history = createHistory(window);

  // experimental dbl tap zoom disabler
  let lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        console.log("stopped zoom");
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={preset}>
            <LocationProvider history={history}>
              {(context) => <App context={context} />}
            </LocationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,

    document.getElementById("root")
  );
}

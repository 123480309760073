import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";

export const Icon = ({ icon, iconPos, sx }) => (
  <Box
    as={icon}
    sx={{
      ml: iconPos === "right" ? 2 : 0,
      mr: iconPos === "left" ? 2 : 0,
      ...sx,
    }}
  />
);

Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  iconPos: PropTypes.oneOf(["right", "left"]).isRequired,
  sx: PropTypes.object,
};

import { useEffect } from "react";

import { store } from "app/store";

import template from "./renew-status.template";

export const RenewStatus = (props) => {
  const renewStatus = store.getState().account.renewStatus;

  useEffect(() => {}, []);

  return template(props, renewStatus);
};

export const UTILS = {
  interpolate: (str, obj) =>
    str.replace(/\${([^}]+)}/g, (_, prop) => obj[prop]),

  /**
   * Accepts a string value and returns it back with an ellipsis if the string length
   * is greater than the max length specified. Otherwise, return the original string.
   */
  summarize: (str, max) => {
    if (str.length <= max) return str;
    const subString = str.substring(0, max - 1);
    return subString.substring(0, subString.lastIndexOf(" ")) + "...";
  },

  getJsonFromUrl: (url) => {
    if (url) {
      const query = url.substr(1);
      const result = {};
      query.split("&").forEach(function (eachvalue) {
        const eachItem = eachvalue.split("=");
        result[eachItem[0]] = eachItem[1];
      });
      return result;
    }
  },

  placeholderFormatter: function () {
    return [...arguments].reduce((p, c, i) => {
      return p.replace(new RegExp("\\{" + (i - 1) + "\\}", "gi"), c);
    });
  },
  /**
   * Accepts an array and returns an array without duplicates.
   * Usage: arrayElement.filter(getUnique)
   */
  getUnique: (value, index, self) => {
    return self.indexOf(value) === index;
  },
  /**
   * Accepts a number and returns absolution number and isNegative(bool).
   * Usage: getNumberData(value)
   * Returns: {value: numString, isNegative: bool}
   */
  getNumberData: (num) => {
    let isNegative = false;
    if (num < 0) {
      isNegative = true;
      num *= -1;
    }
    num = Math.round(num * 100) / 100;
    num = num.toLocaleString() + (num % 1 === 0 ? ".00" : "");
    return { value: num, isNegative };
  },
};

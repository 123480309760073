import { navigate } from "@reach/router";
import { useState } from "react";
import { useWindowSize } from "react-use";

import template from "./connect-account-info.template";
export function ConnectAccountInfo() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize();
  const events$ = {
    onSkipClick: null,
    onModalButtonClick: null,
  };
  events$.onModalButtonClick = () => {
    setIsModalOpen(!isModalOpen);
  };
  events$.onSkipClick = () => {
    navigate("/dashboard");
  };

  return template(width, isModalOpen, setIsModalOpen, events$);
}

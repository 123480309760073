import { Input } from "@rebass/forms";
import { ReactComponent as cancel } from "assets/icons/cancel.svg";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";

import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

const raddi = 15;

export const FormText = ({ label, passIndication, message, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  return (
    <Box
      variant={APP.FORM_TEXT.GLOABL_BOX}
      sx={{
        borderColor:
          meta.touched && meta.error ? preset.colors.redBase : "grey20",
      }}
    >
      <Flex flexDirection="column">
        <Box
          variant={
            meta.touched && meta.error
              ? APP.FORM_TEXT.PADDING_ERROR_CONTENT
              : APP.FORM_TEXT.PADDING_NO_CONTENT
          }
        >
          <Label
            color={preset.colors.darkGrey}
            variant={APP.LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR}
            htmlFor={props.id || props.name}
          >
            {label}
          </Label>
          <Flex flexDirection="row">
            <Input
              className="text-input"
              {...field}
              {...props}
              data-cy={`cy-${props.id}`}
              ml={-2}
              mt={1}
              mb={0}
              sx={{
                fontSize: "18px",
                paddingTop: "0px",
                paddingBottom: "0px",
                lineHeight: "20px",
              }}
            />
            {field.value && (
              <Box
                onClick={() => setValue("")}
                as={cancel}
                ml={2}
                mt={1}
                alignSelf={"center"}
              />
            )}
          </Flex>
        </Box>
        {passIndication && (!meta.touched || !meta.error) && (
          <Box variant={APP.FORM_TEXT.NO_ERROR_BOX}>
            <Label
              variant={APP.LABEL_VARIANT_TYPE.LABEL_SMALL_REGULAR}
              color={preset.colors.black}
              data-cy={`cy-message-${props.id}`}
            >
              {message}
            </Label>
          </Box>
        )}
        {meta.touched && meta.error && (
          <Box backgroundColor="#FFD7D4" variant={APP.FORM_TEXT.ERROR_BOX}>
            <Label
              variant={APP.LABEL_VARIANT_TYPE.LABEL_MEDIUM_REGULAR}
              color={preset.colors.black}
              data-cy={`cy-error-${props.id}`}
            >
              {meta.error}
            </Label>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

FormText.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

import { Form, Formik } from "formik";
import React from "react";
import { Box, Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { FormText } from "^molecules/form-text/form-text.componet";

export default (events$, showResetButton, emailAddress) => {
  return (
    <Box>
      <Flex flexDirection="column">
        <Formik
          initialValues={{ emailAddress: emailAddress || "" }}
          validate={events$.handleValidation}
          onSubmit={events$.handleSubmission}
        >
          {() => {
            return (
              <Form>
                <Box p={3}>
                  <FormText
                    label="Email address"
                    name="emailAddress"
                    id="emailAddress"
                    placeholder=""
                    type="email"
                  />
                </Box>
                <Flex justifyContent="center" pt={2}>
                  <ButtonLarge
                    icon={null}
                    iconPos={null}
                    text="Reset my password"
                    disabled={!showResetButton}
                    variant="largePrimaryDark"
                    type="submit"
                  />
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Box>
  );
};

export default {
  items: [
    {
      firstTimeLogin: false,
      id: "a2e3401a-b052-47f8-b700-4b341d9368bd",
      onBoarded: false,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "a2e3401a-b052-47f8-b700-4b341d9368bd",
    },
    {
      firstTimeLogin: false,
      id: "df861130-241d-4247-b18d-3c8c6f457ca9",
      onBoarded: false,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "df861130-241d-4247-b18d-3c8c6f457ca9",
    },
    {
      firstTimeLogin: false,
      id: "5207b476-476b-4134-acc6-800f3fc10c86",
      onBoarded: false,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "5207b476-476b-4134-acc6-800f3fc10c86",
    },
    {
      firstTimeLogin: false,
      id: "30089b16-e6d6-4d40-b32e-721ec1b90065",
      onBoarded: false,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "30089b16-e6d6-4d40-b32e-721ec1b90065",
    },
    {
      firstTimeLogin: true,
      id: "b2f026fd-5b0f-4a26-b102-e45fd84145e8",
      onBoarded: true,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "b2f026fd-5b0f-4a26-b102-e45fd84145e8",
    },
    {
      firstTimeLogin: true,
      id: "cfd5df22-240f-4ca4-99cc-f1c8fadb6497",
      onBoarded: true,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "cfd5df22-240f-4ca4-99cc-f1c8fadb6497",
    },
    {
      firstTimeLogin: false,
      id: "e8eeb4bd-218e-4433-ae06-b21dafab58f3",
      onBoarded: false,
      registered: false,
      setupConnectedAccounts: false,
      setupCreditScore: false,
      setupGoal: false,
      setupMonthlyPlan: false,
      setupTimePeriod: false,
      user: "e8eeb4bd-218e-4433-ae06-b21dafab58f3",
    },
  ],
};

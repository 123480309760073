import React from "react";
import { Image } from "rebass";
import PropTypes from "prop-types";

import { preset } from "style/preset";
import { APP } from "^constants/app.constant";

const { IMAGE_TYPE } = APP;

const { radii } = preset;

export const SquareImage = ({
  isCurved,
  imgSrc,
  imgType,
  size,
  title,
  ...props
}) => {
  const radius = isCurved ? radii.curvedImage : radii.sharpImage;
  const sx = {
    width: size,
    height: size,
    borderRadius: radius,
    objectFit: "cover",
  };
  return imgType === IMAGE_TYPE.SVG ? (
    <Image as={imgSrc} sx={sx} alt={title} />
  ) : (
    <Image src={imgSrc} sx={sx} alt={title} />
  );
};

SquareImage.propTypes = {
  isCurved: PropTypes.bool,
  imgSrc: PropTypes.string,
  imgType: PropTypes.oneOf([IMAGE_TYPE.SVG, IMAGE_TYPE.OTHERS]),
  size: PropTypes.number,
  title: PropTypes.string,
};

import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as dayjs from "dayjs";

import { Label } from "^atoms/typography/label.component";

export const Greeting = ({ greetingName, ...props }) => {
  const { user } = useSelector((state) => state.app);
  return (
    <Label variant="labelMediumRegular">
      {`Good ${dayjs().hour() > 12 ? "afternoon" : "morning"}, ${
        user?.given_name || "user"
      }`}
    </Label>
  );
};

Greeting.propTypes = {
  greetingName: PropTypes.string,
};

import { navigate } from "@reach/router";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserAction } from "reducers/appReducer";
import { setProfileAction } from "reducers/profileReducer";

import { APP } from "^constants/app.constant";
import { ERRORS } from "^constants/errors.constant";

import loginService from "./login.service";
import template from "./login.template";

const alertInitialData = {
  show: false,
  type: "danger",
  message: "Your email and password do not match",
};

export const Login = (props) => {
  //Start: variable declaration
  const dispatch = useDispatch();

  const events$ = {
    handleSubmit: null,
    handleValidation: null,
  };
  //End: variable declaration

  //Start: state definitions
  const [showSignIn, setSignIn] = useState(false);
  const [alertData, setAlertData] = useState(alertInitialData);
  //End: state definitions

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.alertType)
        setAlertData({
          ...alertData,
          show: true,
          type: props.location.state.alertType,
          message: props.location.state.alertMessage,
        });
      window.history.replaceState(null, "");
    }
  }, []);

  //Start: event handler definitions
  events$.handleValidation = (values) => {
    const errors = {};
    setAlertData({ ...alertData, show: false });
    if (
      values.emailAddress &&
      !APP.REGEX.EMAIL_VALIDATION.test(values.emailAddress)
    )
      errors.emailAddress = ERRORS.LOGIN.INVALID_EMAIL;

    if (
      (values.password && values.password.length < 8) ||
      (values.password && values.password.length > 64)
    )
      errors.password = ERRORS.LOGIN.INVALID_PASSWORD;

    if (values.emailAddress && values.password) {
      if (errors && (errors.emailAddress || errors.password)) {
        setSignIn(false);
      } else {
        setSignIn(true);
      }
    } else {
      setSignIn(false);
    }
    return errors;
  };

  events$.handleSubmit = async (values, actions) => {
    try {
      setAlertData({ ...alertData, show: false });
      const user = await loginService.signIn(
        values.emailAddress,
        values.password
      );

      dispatch(setUserAction(user.attributes));
      let profile = await loginService.getUserProfile(user.username);

      // If dashboard profile is missing the will create
      // the profile for a valid cognito user and load into the profile
      if (!profile.data.getDashboardStatus) {
        await loginService.createDashBoard(user.username);
        profile = await loginService.getUserProfile(user.username);
      }

      dispatch(setProfileAction(profile.data.getDashboardStatus));
      if (!profile.data.getDashboardStatus.firstTimeLogin) {
        await loginService.updateFirstTimeLoginStatus(user.username);
        navigate("connectinfo");
      } else {
        navigate(`dashboard`, {
          state: {
            alertMessage: "You have successfully logged in",
            alertType: "success",
          },
          replace: true,
        });
      }
    } catch (error) {
      if (
        error.code === "UserNotFoundException" ||
        error.code === "NotAuthorizedException" ||
        error.code === "UserNotConfirmedException"
      )
        setAlertData({
          ...alertData,
          show: true,
          type: "danger",
          message: "Your email and password do not match",
        });
      else {
        setAlertData({
          ...alertData,
          show: true,
          type: "danger",
          message: "Something went wrong",
        });
      }
    }
    actions.setSubmitting(false);
  };
  //End: event handler definitions

  return template(props, events$, showSignIn, setSignIn, alertData);
};

import React from "react";
import { Box, Flex } from "rebass";

import { Paragraph } from "^atoms/typography/paragraph.component";
import { MESSAGES } from "^constants/message.constants";

export const RestPasswordLink = ({ emailAddress }) => {
  return (
    <Box>
      <Flex flexDirection="column">
        <Box ml={3} mr={3}>
          <Paragraph variant="paraMediumRegular">
            {`${MESSAGES.FORGOT_PASSWORD.PASSWORD_LINK_PART_ONE} ${emailAddress}`}
          </Paragraph>

          <Paragraph mt={2} variant="paraMediumRegular">
            {`${MESSAGES.FORGOT_PASSWORD.PASSWORD_LINK_PART_TWO}`}
          </Paragraph>
        </Box>
      </Flex>
    </Box>
  );
};

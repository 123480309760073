import { Form, Formik } from "formik";
import React from "react";
import { Box, Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { Steps } from "^atoms/steps/steps.component";
import { ERRORS } from "^constants/errors.constant";
import { MESSAGES } from "^constants/message.constants";
import { FormText } from "^molecules/form-text/form-text.componet";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { preset } from "^style//preset";

export default (events$, width, showSaveButton) => {
  return (
    <Flex flexDirection="column">
      <Box sx={{ width, height: "100vh" }} bg={preset.colors.greyBG}>
        <NavBar
          regScreens
          bg={preset.colors.greyBG}
          textColor={preset.colors.darkGrey}
          noBurgerIcon
        />
        <Steps
          text={MESSAGES.FORGOT_PASSWORD.CREATE_NEW_PASSWORD_PAGE_HEADER}
          stepNumber={3}
        />
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={events$.handleValidation}
          onSubmit={events$.handleSubmission}
        >
          {() => {
            return (
              <Form>
                <Box pl={3} pr={3}>
                  <Box pt={3}>
                    <FormText
                      label="Password"
                      name="password"
                      id="password"
                      placeholder=""
                      type="password"
                      passIndication
                      message={ERRORS.VALIDATION.PASSWORD_ERROR_MESSAGE}
                    />
                  </Box>

                  <Box pt={3} pb={3}>
                    <FormText
                      label="Confirm password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=""
                      type="password"
                    />
                  </Box>

                  <Flex justifyContent="center" pt={2}>
                    <ButtonLarge
                      icon={null}
                      iconPos={null}
                      text="Save"
                      disabled={!showSaveButton}
                      variant="largePrimaryDark"
                      type="submit"
                    />
                  </Flex>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Flex>
  );
};

import React from "react";
import { animated, useSpring } from "react-spring";
import { Box, Flex } from "rebass";

import { CONNECT_ACCOUNT_STEPS } from "^constants/message.constants";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { PreConnect } from "^pages/connect-bank-accounts/pre-connect/pre-connect.component";
import { preset } from "^style/preset";

const cssProperty = {
  textColor: preset.colors.white,
};
export default (props, events$, page, animatedSetPage, width, navigating) => {
  const passedFunctions = {
    animatedSetPage,
    page,
  };
  const style = useSpring({ opacity: !navigating ? 1 : 0 });

  return (
    <Flex flexDirection="column">
      <NavBar
        bg={preset.colors.darkGrey}
        textcolor={cssProperty.textColor}
        noBurgerIcon
        navigateTo="/dashboard"
        handleNavigation={events$.handleNavigation}
        showArrowLeft
        fillColor={preset.colors.white}
      />
      <Box sx={{ width, height: "100vh" }} bg={preset.colors.greyBG}>
        {page === CONNECT_ACCOUNT_STEPS.BANK_LIST && (
          <animated.div style={style}>Bank List </animated.div>
        )}
        {page === CONNECT_ACCOUNT_STEPS.PRECONNECT_SCREEN && (
          <animated.div style={style}>
            <PreConnect {...passedFunctions} />
          </animated.div>
        )}
      </Box>
    </Flex>
  );
};

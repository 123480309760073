import { useWindowSize } from "react-use";

import { CONNECT_ACCOUNT_STEPS } from "^constants/message.constants";

import template from "./pre-connect.template";

export const PreConnect = ({ animatedSetPage, page, ...props }) => {
  //Start: variable declaration
  const events$ = {
    handleBackToProviders: null,
  };

  //End: variable declaration
  events$.handleBackToProviders = () => {
    animatedSetPage(CONNECT_ACCOUNT_STEPS.BANK_LIST);
  };
  //Start: state definitions
  const { width } = useWindowSize();
  return template(props, events$, width, page);
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { Flex, Box } from "rebass";
import { navigate } from "@reach/router";

import { Label } from "^atoms/typography/label.component";
import { ReactComponent as Logo } from "assets/glyphs/logo.svg";

import * as appActions from "reducers/appReducer";
import * as profileActions from "reducers/profileReducer";

export const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      signOut();
    }, 1000);
  });

  async function signOut() {
    try {
      await Auth.signOut();
      dispatch(appActions.setUserAction(null));
      dispatch(profileActions.setProfileAction(null));
      navigate("/", {
        state: { alertMessage: "You're signed out", alertType: "info" },
        replace: true,
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="white"
      fg="darkGrey"
      flexGrow={1}
      sx={{ height: "calc( 100vh - 82px)" }}
    >
      <Box as={Logo} height={100} />
      <Label variant="labelLargeMedium" color="darkGrey" mt={2}>
        See you again, soon!
      </Label>
    </Flex>
  );
};

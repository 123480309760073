import React from "react";
import { Box, Flex } from "rebass";

import { Steps } from "^atoms/steps/steps.component";
import { MESSAGES } from "^constants/message.constants";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { preset } from "^style//preset";

import { EmailVerification } from "./email-varification/email-verification.component";
import { RestPasswordLink } from "./reset-password-link/reset-password-link.component";

export default (
  events$,
  steps,
  currentStep,
  setCurrentStep,
  emailAddress,
  setEmailAddress,
  showResetButton,
  setShowResetButton,
  width
) => {
  return (
    <Flex flexDirection="column">
      <Box sx={{ width, height: "100vh" }} bg={preset.colors.greyBG}>
        <NavBar
          showArrowLeft
          regScreens
          handleNavigation={events$.handleNavigation}
          bg={preset.colors.greyBG}
          textColor={preset.colors.darkGrey}
          noBurgerIcon
        />
        {currentStep === steps.ENTER_EMAIL && (
          <>
            <Steps
              text={MESSAGES.FORGOT_PASSWORD.ENTER_EMAIL_PAGE_HEADER}
              stepNumber={steps.ENTER_EMAIL}
            />
            <EmailVerification
              steps={steps}
              setCurrentStep={setCurrentStep}
              showResetButton={showResetButton}
              setShowResetButton={setShowResetButton}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
            />
          </>
        )}
        {currentStep === steps.CHECK_EMAIL && (
          <>
            <Steps
              text={MESSAGES.FORGOT_PASSWORD.CHECK_EMAIL_PAGE_HEADER}
              stepNumber={steps.CHECK_EMAIL}
            />
            <RestPasswordLink emailAddress={emailAddress} />
          </>
        )}
      </Box>
    </Flex>
  );
};

import "style/masonry.css";

import { ReactComponent as CreditScore } from "assets/icons/creditScore.svg";
import { ReactComponent as HouseholdBills } from "assets/icons/householdBills.svg";
import { ReactComponent as MonthlyPlan } from "assets/icons/monthlyPlan.svg";
import { ReactComponent as MyGoals } from "assets/icons/myGoals.svg";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass";
import * as profileActions from "reducers/profileReducer";

import { Label } from "^atoms/typography/label.component";
import { Alert } from "^molecules/alert/alert.component";
import { Card } from "^molecules/card/card.component";
import { Header } from "^molecules/header/header.component";
import { Onboard } from "^molecules/onboard/onboard.component";

import dashboardService from "./dashboard.service";

const alertInitialData = {
  show: false,
  type: "success",
  message: "You have successfully logged in",
};

export const Dashboard = (props) => {
  const { profile } = useSelector((state) => state.profile);
  const [alertData, setAlertData] = useState(alertInitialData);
  const dispatch = useDispatch();

  useEffect(() => {
    async function setOnboarded() {
      return dashboardService.updateOnBoardedStatus(profile.id);
    }

    return function setOnboardedState() {
      if (profile && !profile.onBoarded) {
        setOnboarded().then((data) => {
          dispatch(
            profileActions.setProfileAction({ ...profile, onBoarded: true })
          );
        });
      }
    };
  }, []);

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.alertType)
        setAlertData({
          ...alertData,
          show: true,
          type: props.location.state.alertType,
          message: props.location.state.alertMessage,
        });
      window.history.replaceState(null, "");
    }
  }, []);

  if (!profile) return null;

  return (
    <>
      <Header heading="My Dashboard" bg="darkGrey" fg="white" />

      <Box ml={3} mr={3}>
        {alertData.show && (
          <Alert type={alertData.type} message={alertData.message} />
        )}
      </Box>

      {!profile.setupConnectedAccounts && (
        <Box>
          <Onboard />
          <Label
            ml={4}
            mt={3}
            mb={0}
            variant="labelSmallRegular"
            color="grey60"
          >
            Some of what you could do...
          </Label>
        </Box>
      )}

      <Box m={2}>
        <Masonry
          breakpointCols={2}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <Card
            cardIcon={MonthlyPlan}
            fg="darkGrey"
            bg="whiteSmooth"
            title={null}
            message="Create my monthly plan"
            actionText={null}
            actionIcon={null}
          />
          <Card
            cardIcon={HouseholdBills}
            fg="darkGrey"
            bg="yellowSmooth"
            title={null}
            message="Organise my household bills"
            actionText={null}
            actionIcon={null}
          />
          <Card
            cardIcon={MyGoals}
            fg="darkGrey"
            bg="purpleSmooth"
            title={null}
            message="Define my savings goals"
            actionText={null}
            actionIcon={null}
          />
          <Card
            cardIcon={CreditScore}
            fg="darkGrey"
            bg="skyblueSmooth"
            title={null}
            message="Find out my credit score"
            actionText={null}
            actionIcon={null}
          />
        </Masonry>
      </Box>

      <Box mb={5} />
    </>
  );
};

export const APP = {
  ROUTE: {},
  REGEX: {
    ALPHABET_ONLY: /^[A-Za-z]+$/,
    EMAIL_VALIDATION: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
    PASSWORD_VALIDATION: /^(?=.{8,64})(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/,
  },
  BUTTON_TYPE: {
    LARGE_PRIMARY_DARK: "largePrimaryDark",
    LARGE_PRIMARY_LIGHT: "largePrimaryLight",
    LARGE_SECONDARY_GREY: "largeSecondaryGrey",
    LARGE_SECONDARY_OUTLINE: "largeSecondaryOutline",
    LARGE_SECONDARY_OUTLINE_DARK: "largeSecondaryOutlineDark",
    MEDIUM_PRIMARY_DARK: "mediumPrimaryDark",
    MEDIUM_PRIMARY_LIGHT: "mediumPrimaryLight",
    MEDIUM_SECONDARY_GREY: "mediumSecondaryGrey",
    MEDIUM_SECONDARY_OUTLINE: "mediumSecondaryOutline",
    SMALL_PRIMARY_DARK: "smallPrimaryDark",
    SMALL_PRIMARY_LIGHT: "smallPrimaryLight",
    SMALL_SECONDARY_GREY: "smallSecondaryGrey",
    SMALL_SECONDARY_OUTLINE: "smallSecondaryOutline",
  },
  LABEL_VARIANT_TYPE: {
    LABEL_SMALL_REGULAR: "labelSmallRegular",
    LABEL_SMALL_REGULAR_LITE: "labelSmallRegularLite",
    LABEL_SMALL_REGULAR_DARK: "labelSmallRegularDark",
    LABEL_SMALL_MEDIUM: "labelSmallMedium",
    LABEL_MEDIUM_REGULAR: "labelMediumRegular",
    LABEL_MEDIUM_REGULAR_LITE: "labelMediumRegularLite",
    LABEL_MEDIUM_MEDIUM: "labelMediumMedium",
    LABEL_LARGE_REGULAR: "labelLargeRegular",
    LABEL_LARGE_MEDIUM: "labelLargeMedium",
    LABEL_TAG_LIGHT: "labelTagLight",
  },
  ACCOUNT_CARD_TYPE: {
    DARK: "dark",
    LIGHT: "light",
    BLUE: "blue",
    TRANSPARENT: "transparent",
  },
  ACCOUNT_LIST_TYPE: {
    DARK: "dark",
    LIGHT: "light",
    BLUE: "blue",
    TRANSPARENT: "transparent",
  },
  ACCOUNT_CONNECTION_CARD_TYPE: {
    DARK: "dark",
    LIGHT: "light",
    BLUE: "blue",
  },
  ACCOUNT_CONNECTION_LIST_TYPE: {
    DARK: "dark",
    LIGHT: "light",
    BLUE: "blue",
  },
  ICON_BUTTON: {
    LARGE_ICON_BUTTON: "largeIconButton",
    MEDIUM_ICON_BUTTON: "mediumIconButton",
    SMALL_ICON_BUTTON: "smallIconButton",
  },
  IMAGE_TYPE: {
    SVG: "svg",
    OTHERS: "others",
  },
  UN_AUTH_ROUTES: [
    "/",
    "/register",
    "/forgotpassword",
    "/resetpassword",
    "/confirmemail",
    "/verifyemail",
  ],
  NAVBAR_EXCLUDED_ROUTES: [
    "/",
    "/register",
    "/logout",
    "/forgotpassword",
    "/resetpassword",
    "/verifyemail",
    "/connectinfo",
    "/connectbank",
  ],
  CURRENCY_SYMBOL: {
    GBP: "£",
    USD: "$",
  },
  NOTIFICATION_MESSAGE: {
    DARK_SUCCESS: "darkSuccess",
    DARK_ERROR: "darkError",
    LIGHT_SUCCESS: "lightSuccess",
    LIGHT_ERROR: "lightError",
  },
  LABEL: {
    SMALL_REGULAR: "labelSmallRegular",
    SMALL_MEDIUM: "labelSmallMedium",
    MEDIUM_REGULAR: "labelMediumRegular",
    MEDIUM_MEDIUM: "labelMediumMedium",
    LARGE_REGULAR: "labelLargeRegular",
    LARGE_MEDIUM: "labelLargeMedium",
  },
  PARAGRAPH_VARIANT: {
    PARA_MEDIUM_REGULAR: "paraMediumRegular",
    PARA_LARGE_MEDIUM: "paraLargeMedium",
  },
  CTA_VARIANT: {
    CTA_SMALL_LETTERS: "ctaSmallLetters",
    CTA_EXTRA_SMALL: "ctaExtraSmall",
    CTA_SMALL: "ctaSmall",
    CTA_MEDIUM: "ctaMedium",
    CTA_LARGE: "ctaLarge",
    CTA_TEXT_LINK: "ctaTextLink",
  },
  ACCOUNT_TYPES: {
    CASH_CURRENT: "Current Account",
    SAVINGS: "Savings Account",
    CARD: "Card",
    INVESTMENT: "Investment",
    LOAN: "Loan Account",
    MORTGAGE_REPAYMENT: "Mortgage Repayment",
    MORTGAGE_INTERESTONLY: "Mortgage Interest only",
    PENSION: "Pension Account",
    PENSION_DEFINEDBENEFIT: "Pension defined benifit",
    PENSION_DEFINEDCONTRIBUTION: "Pension defined contribution",
    ASSET: "Asset",
    PROPERTIES_RESIDENTIAL: "Properties Residential",
    PROPERTIES_BUYTOLET: "Properties Buy Tolet",
  },
  VARIANTS: {
    REFRESH_TEXT: "refreshText",
    MANAGE_ACCOUNTS: "manageAccounts",
    MY_ACCOUNTS_BOX: "myAccountsBox",
    ADD_ACCOUNT: "addAccount",
  },
  ALERT_VARIANTS: {
    ALERT_GLOBAL: "alertGlobalBox",
    ALERT_IMAGE: "alertImage",
    ALERT_MESSAGE: "alertMessage",
  },
  FORM_TEXT: {
    GLOABL_BOX: "formTextGlobalBox",
    ERROR_BOX: "formTextErrorBox",
    NO_ERROR_BOX: "formTextNoErrorBox",
    PADDING_NO_CONTENT: "formTextPaddingNoContent",
    PADDING_ERROR_CONTENT: "formTextPaddingErrorContent",
  },
};

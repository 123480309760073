import { createAction, createReducer } from "@reduxjs/toolkit";

export const setRenewAccount = createAction("renew/account");
export const setRenewStatus = createAction("renew/status");

export const accountReducer = createReducer(
  {
    setRenewAccount: null,
    setRenewStatus: null,
  },
  {
    [setRenewAccount]: (state, action) =>
      (state = { ...state, renewAccount: action.payload }),
    [setRenewStatus]: (state, action) =>
      (state = { ...state, renewStatus: action.payload }),
  }
);

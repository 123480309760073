import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRenewAccount } from "reducers/accountReducer";
import { navigate } from "@reach/router";

import { getUserAccounts } from "^pages/accounts/accounts.service";

import template from "./manage-accounts.template";

export const ManageAccounts = (props) => {
  const [accountsData, setAccountsData] = useState([]);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(true);
  const [deleteAccountData, setDeleteAccountData] = useState({});
  const dispatch = useDispatch();

  const events$ = {
    handleRenew: null,
    handleDisconnect: null,
  };

  useEffect(() => {
    getUserAccounts().then((allUserAccounts) => {
      setAccountsData(allUserAccounts);
    });
  }, []);

  events$.handleRenew = (title, image, data) => {
    let providerData = {
      providerName: title,
      providerImage: image,
      items: data,
      accountCount: data.length,
    };
    dispatch(setRenewAccount(providerData));
    navigate("/renew");
  };

  events$.handleDisconnect = (title, data) => {
    let providerData = {
      providerName: title,
      items: data,
    };
    setDeleteAccountData(providerData);
    setShowDisconnect(true);
  };

  events$.handleDisconnectConfirm = () => {
    // TODO : Disconnect account api integration.
    setShowDisconnect(false);
  };

  return template(
    accountsData,
    showAddAccount,
    events$,
    showDisconnect,
    setShowDisconnect,
    deleteAccountData
  );
};

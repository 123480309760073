import React from "react";
import { Flex, Box } from "rebass";

import { Label } from "^atoms/typography/label.component";
import { ReactComponent as Logo } from "assets/glyphs/logo.svg";

export const NotMobile = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    bg="darkGrey"
    fg="white"
    flexGrow={1}
    sx={{ height: "100vh" }}
  >
    <Flex
      flexDirection="column"
      width={[2 / 3]}
      alignItems="center"
      justifyContent="center"
      bg="grey20"
      p={4}
      sx={{ borderRadius: 20, textAlign: "center" }}
    >
      <Box as={Logo} height={100} />
      <Label m={3} variant="labelLargeMedium" color="darkGrey" mt={2}>
        Sorry, this site sings most sweetly on mobile
      </Label>
      <Label variant="labelSmallRegular" color="darkGrey">
        Please try again on your device ...
      </Label>
    </Flex>
  </Flex>
);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMeasure } from "react-use";

import { Box, Flex, Image } from "rebass";

import { Greeting } from "^atoms/greeting/greeting.component";
import { H1 } from "^atoms/typography/heading.component";
import { CTA } from "^atoms/typography/cta.component";

import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

import { preset } from "style/preset";
import { headerHeightAction } from "reducers/appReducer";

export const Header = ({
  bg,
  fg,
  boxBg,
  heading,
  greetingName,
  actionText,
  content,
  ...props
}) => {
  const [ref, { height }] = useMeasure();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(headerHeightAction(height));
  }, [height, dispatch]);

  return (
    <header>
      <Box ref={ref} bg={boxBg}>
        <Flex bg={bg} color={fg} p={3}>
          <Flex flexGrow={1}>
            <Box>
              <Greeting greetingName={greetingName} />
              <H1>{heading}</H1>
              {actionText && (
                <Flex alignItems="center" mt={3}>
                  <CTA variant="ctaSmall" mr={2}>
                    {actionText}
                  </CTA>
                  <Image as={EditIcon} fill={fg} width={20} alt="edit" />
                </Flex>
              )}
            </Box>
          </Flex>
          {/* <Flex justifyContent="flex-end" alignItems="flex-start">
            <Image
              as={ProfileBlankIcon}
              fill={preset.colors[bg]}
              stroke={preset.colors[fg]}
              height={50}
              width={50}
              alt="image"
            />
          </Flex> */}
        </Flex>
        {content && (
          <Flex bg={bg} p={3}>
            {content}
          </Flex>
        )}
        <svg
          width="100%"
          height="40"
          viewBox="0 0 500 40"
          preserveAspectRatio="none"
        >
          <path d="M0,0 L0,0 Q250,45 500,0 L500,0 Z" fill={preset.colors[bg]} />
        </svg>
      </Box>
    </header>
  );
};

Header.propTypes = {
  content: PropTypes.any,
  heading: PropTypes.string,
  greetingName: PropTypes.string,
  actionText: PropTypes.string,
  bg: PropTypes.string,
  fg: PropTypes.string,
  boxBg: PropTypes.string,
};

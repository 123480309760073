import PropTypes from "prop-types";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";

import { H2 } from "^atoms/typography/heading.component";
import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

import { ReactComponent as NotificationErrorDark } from "assets/icons/notificationJourneyErrorDark.svg";
import { ReactComponent as NotificationErrorLight } from "assets/icons/notificationJourneyErrorLight.svg";
import { ReactComponent as NotificationSuccessDark } from "assets/icons/notificationJourneySuccessDark.svg";
import { ReactComponent as NotificationSuccessLight } from "assets/icons/notificationJourneySuccessLight.svg";

const { notificationMessage } = preset;

const variantIcons = {
  darkSuccess: NotificationSuccessDark,
  darkError: NotificationErrorDark,
  lightSuccess: NotificationSuccessLight,
  lightError: NotificationErrorLight,
};

export function NotificationMessage(props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={notificationMessage[props.variant].background}
    >
      <Box as={variantIcons[props.variant]} />
      <Box px={3} sx={{ textAlign: "center", paddingTop: "26px" }}>
        <H2 color={notificationMessage[props.variant].textColor} variant="h2">
          {props.headerMessage}
        </H2>
      </Box>
      <Box px={3} sx={{ textAlign: "center", paddingTop: "26px" }}>
        <Label
          variant={APP.LABEL_VARIANT_TYPE.LABEL_MEDIUM_REGULAR}
          sx={{ color: notificationMessage[props.variant].textColor }}
        >
          {props.message}
        </Label>
      </Box>
    </Flex>
  );
}

NotificationMessage.propTypes = {
  variant: PropTypes.oneOf([
    APP.NOTIFICATION_MESSAGE.DARK_SUCCESS,
    APP.NOTIFICATION_MESSAGE.DARK_ERROR,
    APP.NOTIFICATION_MESSAGE.LIGHT_SUCCESS,
    APP.NOTIFICATION_MESSAGE.LIGHT_ERROR,
  ]),
  headerMessage: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

import { useNavigate } from "@reach/router";
import ENV from "environments/env";
import { useState } from "react";
import { useWindowSize } from "react-use";

import template from "./forgot-password.template";

export const ForgotPassword = () => {
  //Start: variable declaration
  const events$ = {
    handleNavigation: null,
  };

  console.log("ENV", ENV.ENVIRONMENT_NAME);

  const steps = {
    ENTER_EMAIL: 1,
    CHECK_EMAIL: 2,
  };
  //End: variable declaration

  //Start: state definitions
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [currentStep, setCurrentStep] = useState(steps.ENTER_EMAIL);
  const [emailAddress, setEmailAddress] = useState("");
  const [showResetButton, setShowResetButton] = useState(false);
  //End: state definitions

  //Start: event handler definitions
  events$.handleNavigation = async () => {
    if (currentStep === steps.CHECK_EMAIL) {
      setCurrentStep(steps.ENTER_EMAIL);
    } else {
      await navigate("/");
    }
  };
  //End: event handler definitions

  return template(
    events$,
    steps,
    currentStep,
    setCurrentStep,
    emailAddress,
    setEmailAddress,
    showResetButton,
    setShowResetButton,
    width
  );
};

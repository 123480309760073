import PropTypes from "prop-types";
import React from "react";
import { animated, useSpring } from "react-spring";
import { Box, Flex } from "rebass";
import preset from "style/preset";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { Label } from "^atoms/typography/label.component";
import { MESSAGES } from "^constants/message.constants";

export const Modal = ({
  bg,
  fg,
  content,
  isModalOpen,
  setIsModalOpen,
  showTextLink = false,
  textForTextLink = "",
  dismissText = "Close",
  ...props
}) => {
  const modalStyle = useSpring({
    transform: isModalOpen ? `translateY(0)` : `translateY(100%)`,
  });

  const AnimatedBox = animated(Box);

  return (
    <AnimatedBox
      style={modalStyle}
      bg="transparent"
      sx={{
        zIndex: 2,
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
      }}
    >
      <Box
        bg={preset.colors.black}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          opacity: 0.25,
        }}
      />
      <Flex
        flexDirection="column"
        bg={preset.colors.white}
        fg={fg}
        flexGrow={1}
        height={props.height ? props.height : "calc(100% - 32px)"}
        m={3}
        overflow="auto"
        sx={{
          zIndex: 2,
          position: "absolute",
          top: props.top ? props.top : 0,
          left: 0,
          bottom: props.bottom ? props.bottom : 0,
          borderRadius: 20,
        }}
      >
        <Flex
          m={3}
          sx={{ borderRadius: 0 }}
          bg={bg}
          flexGrow={1}
          alignItems="flex-start"
          justifyContent="center"
        >
          {content}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={2}
          pb={3}
        >
          <ButtonLarge
            text={dismissText}
            variant="largePrimaryDark"
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
          {showTextLink && (
            <Label
              variant="ctaSmall"
              sx={{ fontWeight: 700 }}
              mt={16}
              onClick={() => props.handleLinkAction()}
            >
              {textForTextLink}
            </Label>
          )}

          {!props.hideFooter && (
            <Label
              variant="labelSmallRegular"
              ml={5}
              mr={5}
              mt={3}
              mb={3}
              sx={{ textAlign: "center" }}
            >
              {MESSAGES.APP_NAME} is backed by Nationwide Building Society
            </Label>
          )}
        </Flex>
      </Flex>
    </AnimatedBox>
  );
};

Modal.propTypes = {
  content: PropTypes.any,
  bg: PropTypes.string,
  fg: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  showTextLink: PropTypes.bool,
  textForTextLink: PropTypes.string,
  linkForTextLink: PropTypes.string,
  hideFooter: PropTypes.bool,
};

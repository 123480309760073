import React from "react";
import PropTypes from "prop-types";
import { Text } from "rebass";

export const Label = ({ variant, children, ...props }) => (
  <Text variant={variant} {...props}>
    {children}
  </Text>
);

Label.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.any,
};

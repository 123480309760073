import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";

import { AccountConnectionCard } from "^molecules/account-conn-card/account-conn-card.component";
import preset from "style/preset";
import { APP } from "^constants/app.constant";

const { accountConnListVariants } = preset;
const {
  ACCOUNT_CONNECTION_LIST_TYPE: LIST_VARIANTS,
  ACCOUNT_CONNECTION_CARD_TYPE: CARD_VARIANTS,
} = APP;

export const AccountConnectionList = ({
  cardItems,
  listVariant,
  cardVariant,
  ...props
}) => {
  const { boxSx, cardContainerStyle } = accountConnListVariants[listVariant];
  console.log(cardItems);
  return (
    <Box {...props} sx={boxSx}>
      <Flex justifyContent="center" flexDirection="column">
        {cardItems.map((cardItemProps, i) => (
          <Box key={`accConnCard${i}`} sx={cardContainerStyle}>
            <AccountConnectionCard
              {...cardItemProps}
              cardVariant={cardVariant}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

AccountConnectionList.propTypes = {
  cardItems: PropTypes.array,
  listVariant: PropTypes.oneOf([
    LIST_VARIANTS.DARK,
    LIST_VARIANTS.LIGHT,
    LIST_VARIANTS.BLUE,
    LIST_VARIANTS.TRANSPARENT,
  ]),
  cardVariant: PropTypes.oneOf([
    CARD_VARIANTS.DARK,
    CARD_VARIANTS.LIGHT,
    CARD_VARIANTS.BLUE,
  ]),
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { animated, useSpring } from "react-spring";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { Link } from "@reach/router";

import { currentPageAction } from "reducers/appReducer";

import { Divider } from "^atoms/divider/divider.component";
import { Label } from "^atoms/typography/label.component";
import { CTA } from "^atoms/typography/cta.component";

import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Dashboard } from "assets/icons/mainNavDashboard.svg";
import { ReactComponent as Household } from "assets/icons/mainNavHousehold.svg";
import { ReactComponent as Accounts } from "assets/icons/mainNavAccounts.svg";
import { ReactComponent as Profile } from "assets/icons/mainNavProfile.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";

import { preset } from "style/preset";

const useStyles = makeStyles((props) => ({
  root: {
    zIndex: 3,
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "calc(100% - 20px)",
    background: preset.colors.greyBG,
    "& a": {
      outline: "none",
      color: preset.colors.darkGrey,
      textDecoration: "none",
    },
  },
}));

export const Navigation = ({ style, setIsNavOpen, isNavOpen, ...props }) => {
  const classes = useStyles({ isNavOpen });
  const dispatch = useDispatch();

  const navStyle = useSpring({
    config: { mass: 0.5, tension: 200, velocity: 10 },
    transform: isNavOpen ? `translateX(0)` : `translateX(100%)`,
    delay: 10,
  });

  const navigateTo = (path) => {
    dispatch(currentPageAction(path));
    setIsNavOpen(false);
  };

  if (!isNavOpen) return null;

  return (
    <animated.div style={navStyle} className={classes.root}>
      <Flex alignItems="center" p={3} mt={2}>
        <Flex>
          <Label variant="labelMediumRegular">Menu</Label>
        </Flex>
        <Flex
          flexGrow={1}
          justifyContent="flex-end"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <Close />
        </Flex>
      </Flex>
      <Box>
        <NavItem
          icon={Dashboard}
          label="My dashboard"
          to="/dashboard"
          navigateTo={navigateTo}
        />
        <Divider />
        <NavItem
          icon={Household}
          label="My household"
          to="/household"
          navigateTo={navigateTo}
        />
        <Divider />
        <NavItem
          icon={Accounts}
          label="My accounts"
          to="/accounts"
          navigateTo={navigateTo}
        />
        <Divider />
        <NavItem
          icon={Profile}
          label="Profile"
          to="/profile"
          navigateTo={navigateTo}
        />
        <Divider />
      </Box>
      <Flex flexDirection="column" flexGrow={1}>
        <SubNavItemWithIcon
          to="/logout"
          label="Log Out"
          navigateTo={navigateTo}
          icon={Logout}
        />
        <SubNavItem
          pt={2}
          to="/contact"
          label="Contact Us"
          navigateTo={navigateTo}
        />
        <SubNavItem to="/about" label="About" navigateTo={navigateTo} />
        <SubNavItem to="/legal" label="Legal Stuff" navigateTo={navigateTo} />
        <Flex flexGrow={1} alignItems="flex-end">
          <Label variant="labelSmallRegular" p={3}>
            &copy; {`Homehub ${new Date().getFullYear()}`}
          </Label>
        </Flex>
      </Flex>
    </animated.div>
  );
};

const NavItem = (props) => {
  const { icon, label, to, navigateTo } = props;
  const { app } = useSelector((state) => state);

  return (
    <Flex
      bg={app.currentPage === to ? preset.colors.grey20 : preset.colors.white}
      p={3}
      alignItems="center"
      as={Link}
      to={to}
      onClick={() => navigateTo(to)}
    >
      <Box
        as={icon}
        width={30}
        height={30}
        fill="greyDark"
        stroke="none"
        mr={3}
      />
      <Label variant="labelLargeMedium" sx={{ flexGrow: 1 }} color="greyDark">
        {label}
      </Label>
    </Flex>
  );
};

const SubNavItem = (props) => {
  const { label, to, navigateTo } = props;
  console.log(Logout);
  return (
    <CTA
      variant="ctaSmall"
      pt={3}
      pb={1}
      pl={3}
      as={Link}
      to={to}
      onClick={() => navigateTo(to)}
      {...props}
    >
      {label}
    </CTA>
  );
};

const SubNavItemWithIcon = (props) => {
  const { label, to, navigateTo, icon } = props;
  console.log(Logout);
  return (
    <Flex
      pl={3}
      pb={3}
      pt={4}
      alignItems="center"
      as={Link}
      to={to}
      onClick={() => navigateTo(to)}
    >
      <Box
        as={icon}
        width={30}
        height={30}
        fill="greyDark"
        stroke="none"
        mr={2}
      />
      <CTA variant="ctaSmall">{label}</CTA>
    </Flex>
  );
};

Navigation.propTypes = {
  isNavOpen: PropTypes.bool,
  style: PropTypes.object,
  setIsNavOpen: PropTypes.func,
  label: PropTypes.string,
};

NavItem.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  to: PropTypes.string,
  navigateTo: PropTypes.func,
};

SubNavItem.propTypes = {
  label: PropTypes.string,
  navigateTo: PropTypes.func,
  isOpen: PropTypes.bool,
};

import { useEffect, useState } from "react";
import { getUserAccounts } from "./accounts.service";

import template from "./accounts.template";

export const Accounts = (props) => {
  const [accountsData, setAccountsData] = useState([]);
  const [showAddAccount, setShowAddAccount] = useState(true);
  useEffect(() => {
    getUserAccounts().then((allUserAccounts) => {
      setAccountsData(allUserAccounts);
    });
  }, []);
  return template(accountsData, showAddAccount);
};

import { Form, Formik } from "formik";
import React from "react";
import { Box, Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { FormText } from "^molecules/form-text/form-text.componet";

export default (initialValues, validationSchema, onSubmit, width) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        const { firstName, lastName } = props.values;
        const { isValid } = props;
        const validate = firstName && lastName && isValid;

        return (
          <Form>
            <Box p={3}>
              <FormText
                id="firstName"
                name="firstName"
                type="text"
                label="Your first name"
              />
            </Box>
            <Box p={3}>
              <FormText
                id="lastName"
                name="lastName"
                type="text"
                label="Your last name"
              />
            </Box>
            <Flex justifyContent="center" marginTop="20px">
              <ButtonLarge
                id="userRegistrationNextButton"
                disabled={!validate}
                text="Next"
                variant="largePrimaryDark"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  padding: "14px",
                  width,
                }}
              />
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

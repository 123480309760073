import { UTILS } from "^utilities/common";
import { APP } from "^constants/app.constant";

const { ACCOUNT_CARD_TYPE, ACCOUNT_LIST_TYPE } = APP;

export const getListData = (accountsData) => {
  const uniqueTypes = accountsData
    .map((account) => account.type)
    .filter(UTILS.getUnique)
    .sort();
  return uniqueTypes.map((type) => {
    const cardItems = accountsData
      .filter((account) => account.type === type)
      .sort((a, b) => (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1));
    let value = cardItems
      .map((cardItem) => cardItem.accBalance)
      .reduce((a, b) => a + b, 0);
    return {
      title: type,
      value,
      currency: cardItems[0].currency,
      cardItems,
      listVariant: ACCOUNT_LIST_TYPE.LIGHT,
      cardVariant: ACCOUNT_CARD_TYPE.LIGHT,
    };
  });
};

import PropTypes from "prop-types";
import React from "react";
import { Flex } from "rebass";
import { preset } from "style/preset";

import { Label } from "^atoms/typography/label.component";
import { APP } from "^constants/app.constant";

export const OrderedListItem = ({
  number = true,
  messages = [],
  width = "25px",
  height = "25px",
  textColor,
  textwidth,
  bgColor,
  labelColor = preset.colors.black,
}) => {
  const { LABEL_VARIANT_TYPE } = APP;
  return (
    <>
      {messages &&
        messages.map((message, i) => (
          <Flex pb={3} alignItems="center" key={i}>
            <Label
              variant={LABEL_VARIANT_TYPE.LABEL_MEDIUM_MEDIUM}
              sx={{
                borderRadius: "50%",
                width: width,
                height: height,
                padding: "4px",
                backgroundColor: bgColor,
                textAlign: "center",
                color: labelColor,
                mr: 2,
              }}
            >
              {number && i + 1}
            </Label>
            <Label
              variant={LABEL_VARIANT_TYPE.LABEL_MEDIUM_REGULAR}
              sx={{ color: textColor, width: textwidth }}
            >
              {message}
            </Label>
          </Flex>
        ))}
    </>
  );
};
OrderedListItem.propTypes = {
  number: PropTypes.bool,
  messages: PropTypes.array.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  textColor: PropTypes.string,
  textwidth: PropTypes.string,
  bgColor: PropTypes.string,
  labelColor: PropTypes.string,
};

export default {
  items: [
    {
      bankRef: "halifax",
      country: "GB",
      id: "a5ebcaf3-6e5e-4c32-9730-b8f179c1d5c6",
      isBeta: false,
      name: "Hali Fax",
      parentRef: "halifax",
      type: "api",
      accountTypes: {
        beta: false,
        name: "card",
      },
    },
    {
      bankRef: "monzo",
      country: "GB",
      id: "a5ebcaf3-6e5e-4c32-9730-b8f179c1d5c4",
      isBeta: false,
      name: "Monzo",
      parentRef: "monzo",
      type: "api",
      accountTypes: {
        beta: false,
        name: "card",
      },
    },
    {
      bankRef: "hsbc",
      country: "GB",
      id: "a5ebcaf3-6e5e-4c32-9730-b8f179c1d5c5",
      isBeta: false,
      name: "HSBC",
      parentRef: "hsbc",
      type: "api",
      accountTypes: {
        beta: false,
        name: "card",
      },
    },
    {
      bankRef: "flexplus",
      country: "GB",
      id: "a5ebcaf3-6e5e-4c32-9730-b8f179c1d5c3",
      isBeta: false,
      name: "Flex Plus",
      parentRef: "flexplus",
      type: "api",
      accountTypes: {
        beta: false,
        name: "card",
      },
    },
    {
      bankRef: "amex",
      country: "GB",
      id: "a5ebcaf3-6e5e-4c32-9730-b8f179c1d5c7",
      isBeta: false,
      name: "Amex",
      parentRef: "amex",
      type: "api",
      accountTypes: {
        beta: false,
        name: "card",
      },
    },
  ],
};

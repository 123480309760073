import { LocationProvider, Router } from "@reach/router";
import React from "react";

import { Layout } from "^organisms/layout/layout.component";
import { AboutComponent } from "^pages/about/about.component";
import { Accounts } from "^pages/accounts/accounts.component";
import { ConnectAccountInfo } from "^pages/connect-bank-accounts/connect-account-info/connect-account-info.component";
import { ConnectFailure } from "^pages/connect-bank-accounts/connect-bank-failure/connect-bank-failure.component";
import { ConnectSuccess } from "^pages/connect-bank-accounts/connect-bank-success/connect-bank-success.component";
import { ConnectBank } from "^pages/connect-bank-accounts/connect-bank.component";
import { Contact } from "^pages/contact/contact.component";
import { Dashboard } from "^pages/dashboard/dashboard.component";
import { ForgotPassword } from "^pages/forgot-password/forgot-password.component";
import { Household } from "^pages/household/household.componet";
import { Legal } from "^pages/legal/legal.componet";
import { Login } from "^pages/login/login.component";
import { Logout } from "^pages/logout/logout.component";
import { ManageAccounts } from "^pages/manage-accounts/manage-accounts.component";
import { MockHandlerComponent } from "^pages/mock-handlers/mock-handler.component";
import { Profile } from "^pages/profile/profile.component";
import { EmailVerified } from "^pages/registration/email-verified/email-verified.component";
import { Registration } from "^pages/registration/registration.component";
import { BankProvider } from "^pages/renew-account/bank-provider/bank-provider.component";
import { Connecting } from "^pages/renew-account/connecting/connecting.component";
import { RenewStatus } from "^pages/renew-account/renew-status/renew-status.component";
import { Renew } from "^pages/renew-account/renew/renew.component";
import { ResetPassword } from "^pages/reset-password/reset-password.component";

const App = () => (
  <LocationProvider>
    {({ location }) => (
      <Layout location={location}>
        <Router location={location} primary={false}>
          <Login path="/" />
          <Dashboard path="/dashboard/*" />
          <Household path="/household" />
          <Accounts path="/accounts" />
          <Profile path="/profile" />
          <AboutComponent path="/about" />
          <Contact path="/contact" />
          <Legal path="/legal" />
          <Registration path="/register" />
          <Logout path="/logout" />
          <ForgotPassword path="/forgotpassword" />
          <ResetPassword path="/resetpassword" />
          <EmailVerified path="/verifyemail" />
          <Renew path="/renew" />
          <ConnectBank path="/connectbank" />
          <ConnectAccountInfo path="/connectinfo" />
          <Connecting path="/connecting" />
          <ConnectSuccess path="/connect-success" />
          <ConnectFailure path="/connect-failure" />
          <ManageAccounts path="/manageaccounts" />
          <RenewStatus path="/renew-status" />
          <BankProvider path="/bank-provider" />
          <MockHandlerComponent path="/mock-handler" />
        </Router>
      </Layout>
    )}
  </LocationProvider>
);

export default App;

import { navigate } from "@reach/router";
import Provider from "assets/icons/monzo.png";
import { useEffect, useState } from "react";

import { store } from "^app/store";

import template from "./renew.template";

export const Renew = (props) => {
  const [providerData, setProviderData] = useState({});

  const events$ = {
    handleRenew: null,
  };

  useEffect(() => {
    let data = store.getState().account.renewAccount;
    setProviderData(data);
  }, []);

  events$.handleRenew = () => {
    navigate("/bank-provider", { replace: true });
  };

  return template(props, events$, providerData);
};

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:93a413c6-ae40-413f-ba6f-3dd79bd6367e",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_1mfH6zEm8",
    "aws_user_pools_web_client_id": "2dsqcufb86pikvmh6dd8qcnquc",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fgadlabspbfb3fzlokbpb7d6qu.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDashboardStatus = /* GraphQL */ `
  query GetDashboardStatus($id: ID!) {
    getDashboardStatus(id: $id) {
      id
      user
      registered
      setupConnectedAccounts
      setupMonthlyPlan
      setupTimePeriod
      setupGoal
      setupCreditScore
      firstTimeLogin
      onBoarded
      dashboardCards {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardStatuss = /* GraphQL */ `
  query ListDashboardStatuss(
    $filter: ModelDashboardStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        registered
        setupConnectedAccounts
        setupMonthlyPlan
        setupTimePeriod
        setupGoal
        setupCreditScore
        firstTimeLogin
        onBoarded
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      name
      total {
        value
        currency
      }
      dateCreated
      progressPercentage
      progressAmount {
        value
        currency
      }
      accounts {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        progressPercentage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      accountName
      balance {
        value
        currency
      }
      details {
        AER
        APR
        sortCodeAccountNumber
        iban
        creditLimit
        endDate
        fixedDate
        interestFreePeriod
        interestType
        linkedProperty
        monthlyRepayment
        overdraftLimit
        postcode
        runningCost
        runningCostPeriod
        term
        yearlyAppreciation
      }
      dateAdded
      dateModified
      id
      providerName
      providerReference
      connectionId
      providerId
      accountReference
      accountHolderName
      type
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountHolderName
      accountName
      accountReference
      balance {
        currency
        value
      }
      connectionId
      createdAt
      dateAdded
      dateModified
      details {
        AER
        APR
        creditLimit
        endDate
        fixedDate
        iban
        interestFreePeriod
        interestType
        linkedProperty
        monthlyRepayment
        overdraftLimit
        postcode
        runningCost
        runningCostPeriod
        sortCodeAccountNumber
        term
        yearlyAppreciation
      }
      id
      providerId
      providerName
      providerReference
      type
      updatedAt
      }
      nextToken
    }
  }
`;
export const getBankIcons = /* GraphQL */ `
  query GetBankIcons($id: ID!) {
    getBankIcons(id: $id) {
      id
      source
      type
      createdAt
      updatedAt
    }
  }
`;
export const listBankIconss = /* GraphQL */ `
  query ListBankIconss(
    $filter: ModelBankIconsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankIconss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBank = /* GraphQL */ `
  query GetBank($id: ID!) {
    getBank(id: $id) {
      id
      name
      type
      isBeta
      country
      parentRef
      bankRef
      accountTypes {
        beta
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBanks = /* GraphQL */ `
  query ListBanks(
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        isBeta
        country
        parentRef
        bankRef
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

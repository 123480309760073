import { navigate } from "@reach/router";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box } from "rebass";
import { preset } from "style/preset";

import { APP } from "^constants/app.constant";
import { NavBar } from "^molecules/nav-bar/nav-bar.component";
import { Navigation } from "^molecules/navigation/navigation.componet";

export const Layout = ({ children, ...props }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { location } = props;
  const style = useSpring({ opacity: !isNavOpen ? 1 : 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
    handleAuthCheck();
  }, [isNavOpen]);

  const handleAuthCheck = async () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        // INFO : Redirect to dashboard whenever unauth routes accessed after login.
        if (APP.UN_AUTH_ROUTES.includes(location.pathname))
          navigate("/dashboard");
      })
      .catch((err) => {
        // INFO : Redirect to landing page whenever auth routes accessed without valid logged in user.
        if (!APP.UN_AUTH_ROUTES.includes(location.pathname)) navigate("/");
      });
  };

  return (
    <>
      {!APP.NAVBAR_EXCLUDED_ROUTES.includes(location.pathname) ? (
        <>
          <Navigation setIsNavOpen={setIsNavOpen} isNavOpen={isNavOpen} />
          <NavBar
            bg="darkGrey"
            fg="white"
            setIsNavOpen={setIsNavOpen}
            isNavOpen={isNavOpen}
          />
          <animated.div style={style}>{children}</animated.div>
        </>
      ) : (
        <Box id="layout" sx={{ height: "100vh" }} bg={preset.colors.greyBG}>
          <animated.div style={style}>{children}</animated.div>
        </Box>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

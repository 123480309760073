import React from "react";
import { Flex } from "rebass";

import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { H3 } from "^atoms/typography/heading.component";
import { APP } from "^utilities/constants/app.constant";

export default (props, events$) => {
  return (
    <Flex flexDirection="column" alignItems="center" sx={{ height: "100vh" }}>
      <Flex mt={3}>
        <H3>DUMMY BANK PAGE</H3>
      </Flex>

      <Flex mt={4}>
        <ButtonLarge
          variant={APP.BUTTON_TYPE.LARGE_PRIMARY_DARK}
          text="Success Response"
          onClick={() => events$.handleResponse(true)}
        />

        <ButtonLarge
          variant={APP.BUTTON_TYPE.LARGE_SECONDARY_GREY}
          text="Failure Response"
          onClick={() => events$.handleResponse(false)}
        />
      </Flex>
    </Flex>
  );
};

import { Link } from "@reach/router";
import Logo from "assets/icons/logo.png";
import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Image } from "rebass";

import { H1 } from "^atoms/typography/heading.component";
import { Paragraph } from "^atoms/typography/paragraph.component";
import { preset } from "style/preset";

export const PageHeader = (props) => {
  return (
    <Flex
      flexDirection="column"
      alignItems={props.headingLeft ? "flex-start" : "center"}
    >
      {props.showLogo ? (
        <Box mb={1}>
          <Link to="/">
            {" "}
            <Image src={Logo} sx={{ marginTop: "34px" }} alt="Logo" />
          </Link>
        </Box>
      ) : (
        ""
      )}

      {props.breadcrumb ? (
        <Box>
          <Paragraph color="text" variant="paraMediumRegular">
            {props.breadcrumb}
          </Paragraph>
        </Box>
      ) : (
        ""
      )}

      {props.heading ? (
        <Box mt={3}>
          <H1 color={preset.colors.darkGrey}>{props.heading}</H1>
        </Box>
      ) : (
        ""
      )}

      {props.description ? (
        <Box mt={2} sx={{ marginBottom: "20px" }}>
          <Paragraph
            color={preset.colors.darkGrey}
            variant="paraMediumRegular"
            sx={{ textAlign: props.headingLeft ? "left" : "center" }}
          >
            {props.description}
          </Paragraph>
        </Box>
      ) : (
        ""
      )}
    </Flex>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string,
  breadcrumb: PropTypes.string,
  description: PropTypes.string,
  showLogo: PropTypes.bool,
  headingLeft: PropTypes.bool,
};

import { Link } from "@reach/router";
import { Form, Formik } from "formik";
import React from "react";
import { Box, Flex } from "rebass";

import { ReactComponent as ArrowRight } from "^assets/icons/arrowRight.svg";
import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { Label } from "^atoms/typography/label.component";
import { TextLink } from "^atoms/typography/text-link.component";
import { MESSAGES } from "^constants/message.constants";
import { Alert } from "^molecules/alert/alert.component";
import { FormText } from "^molecules/form-text/form-text.componet";
import { PageHeader } from "^molecules/page-header/page-header.component";
import { preset } from "^style//preset";

export default (props, events$, showSignIn, setSignIn, alertData) => {
  return (
    <Flex flexDirection="column" bg={preset.colors.greyBG}>
      <Box ml={3} mr={3}>
        <PageHeader
          heading={MESSAGES.LOGIN.WELCOME_MESSAGE}
          description={MESSAGES.LOGIN.WELCOME_DESCRIPTION}
          showLogo
        />

        {alertData.show && (
          <Alert type={alertData.type} message={alertData.message} />
        )}

        <Formik
          initialValues={{
            emailAddress: "",
            password: "",
          }}
          validate={events$.handleValidation}
          onSubmit={events$.handleSubmit}
        >
          {() => (
            <Form>
              <Flex pt={1} pb={1}>
                <Box sx={{ width: "100%" }}>
                  <FormText
                    id="emailAddress"
                    name="emailAddress"
                    type="email"
                    label="Email address"
                  />
                </Box>
              </Flex>

              <Flex pt={2}>
                <Box sx={{ width: "100%" }}>
                  <FormText
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                  />
                </Box>
              </Flex>

              <Flex justifyContent="center" mt={2}>
                <Box mt={1}>
                  <Link to="/forgotpassword" style={preset.styles.link}>
                    <TextLink
                      color={preset.colors.grey80}
                      variant="textLinkSmall"
                      sx={{ fontWeight: 700 }}
                    >
                      {MESSAGES.LOGIN.FORGOT_PASSWORD_TEXT}
                    </TextLink>
                  </Link>
                </Box>
              </Flex>

              <Flex justifyContent="center" sx={{ marginTop: "24px" }}>
                <Box>
                  <ButtonLarge
                    id="loginButton"
                    disabled={showSignIn ? false : true}
                    text="Sign in"
                    variant="largePrimaryDark"
                    type="submit"
                  />
                </Box>
              </Flex>

              <Flex mt={3} pb={4}>
                <Box width={1 / 2} justifyContent="flex-end" mt={1} mr={1}>
                  <Label
                    variant="labelSmallRegular"
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    New here?
                  </Label>
                </Box>
                <Box width={1 / 2} sx={{ marginTop: "2px" }}>
                  <Link to="/register" style={preset.styles.link}>
                    <TextLink
                      sx={{
                        color: preset.colors.grey80,
                        textTransform: "none",
                        float: "left",
                        fontWeight: 700,
                      }}
                      variant="textLinkSmall"
                    >
                      Join now
                    </TextLink>
                    <ArrowRight />
                  </Link>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

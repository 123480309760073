import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Box, Flex, Text } from "rebass";
import {
  Label as RebassLabel,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
} from "@rebass/forms";
import { preset } from "style/preset";

import { Label } from "^atoms/typography/label.component";

export const FormCheck = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <Box p={3}>
      <RebassLabel
        className="checkbox"
        sx={
          meta.touched &&
          meta.error && {
            borderLeft: "5px solid rgba(255,88,74,1)",
            padding: "8px",
          }
        }
      >
        <Checkbox type="checkbox" {...field} {...props} />
        <Label variant="labelMediumRegular" alignSelf="center">
          {children}
        </Label>
      </RebassLabel>
      {meta.touched && meta.error ? (
        <>
          <Box
            p={2}
            backgroundColor="#FFD7D4"
            sx={{
              borderColor: preset.colors.redBase,
              borderLeft: "5px solid rgba(255,88,74,1)",
            }}
          >
            <Label variant="labelMediumRegular" color={preset.colors.black}>
              {meta.error}
            </Label>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

FormCheck.propTypes = {
  children: PropTypes.any,
};

export const preset = {
  body: {
    margin: 0,
    padding: 0,
  },
  fonts: {
    regular: "'GraphikRegular', sans-serif",
    medium: "'GraphikMedium', sans-serif",
    body: "'GraphikLight', sans-serif",
    heading: "GraphikMedium",
    mono: "Courier, Consolas, monospace",
  },
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    black: "rgba(21,27,35,1)",
    grey90: "rgba(38,46,58,1)",
    darkGrey: "rgba(55,66,82,1)",
    grey80: "rgba(87,96,110,1)",
    grey60: "rgba(141,151,164,1)",
    grey40: "rgba(181,189,203,1)",
    grey20: "rgba(216,222,230,1)",
    grey10: "rgba(230,235,241,1)",
    greyBG: "rgba(247,248,250,1)",
    white: "rgba(255,255,255,1)",
    redBase: "rgba(255,88,74,1)",
    redSmoothLight: "rgba(255,88,74,.4)",
    redSmooth: "rgba(255,215,212,1)",
    greenBase: "rgba(58,178,119,1)",
    greenSmoothLight: "rgba(58,178,119,.4)",
    greenSmooth: "rgba(197,232,215,1)",
    limegreenBase: "rgba(132,216,102,1)",
    limegreenSmoothLight: "rgba(132,216,102,.4)",
    limegreenSmooth: "rgba(219,244,210,1)",
    yellowBase: "rgba(255,203,87,1)",
    yellowSmoothLight: "rgba(255,203,87,.4)",
    yellowSmooth: "rgba(255,240,205,1)",
    whiteBase: "rgba(255,255,255,1)",
    whiteSmoothLight: "rgba(255,255,255,1)",
    whiteSmooth: "rgba(255,255,255,1)",
    orangeBase: "rgba(255,128,93,1)",
    orangeSmoothLight: "rgba(255,128,93,.4)",
    orangeSmooth: "rgba(255,223,214,1)",
    orangeAction: "rgba(255,99,86,1)",
    brownBase: "rgba(122,82,78,1)",
    brownSmoothLight: "rgba(122,82,78,.4)",
    brownSmooth: "rgba(234,220,219,1)",
    purpleBase: "rgba(89,86,233,1)",
    purpleSmoothLight: "rgba(89,86,233,.4)",
    purpleSmooth: "rgba(220,219,255,1)",
    blueBase: "rgba(52,107,225,1)",
    blueSmoothLight: "rgba(52,107,225,.4)",
    blueSmooth: "rgba(209,224,255,1)",
    skyblueBase: "rgba(86,189,233,1)",
    skyblueSmoothLight: "rgba(86,189,233,.4)",
    skyblueSmooth: "rgba(205,236,249,1)",
    tealBase: "rgba(90,226,226,1)",
    tealSmoothLight: "rgba(90,226,226,.4)",
    tealSmooth: "rgba(206,247,247,1)",
    pinkBase: "rgba(252,119,160,1)",
    pinkSmoothLight: "rgba(252,119,160,.4)",
    pinkSmooth: "rgba(255,235,239,1)",
    blueCardOutline: "rgb(87, 96, 110)",
  },
  fontSizes: [8, 10, 12, 14, 16, 18],
  fontWeights: {
    body: 100,
    heading: 100,
    bold: 100,
  },
  lineHeights: {
    body: 1.3,
    heading: 1,
  },
  // space: [0, 1, 2, 4, 8, 12, 16, 32, 64, 128, 256, 512],
  space: [0, 4, 8, 16, 32, 64],
  sizes: {
    avatar: 48,
    cardImage: 34,
    squareImage: 250,
  },
  radii: {
    default: 4,
    circle: 99999,
    curvedBox: 20,
    sharpBox: 0,
    curvedImage: 8,
    sharpImage: 0,
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
  },
  accountCardVariants: {
    dark: {
      isCurvyBox: false,
      textColor: "white",
      bgColor: "grey90",
      padding: "16px 16px 16px 0px",
    },
    light: {
      isCurvyBox: true,
      textColor: "black",
      bgColor: "white",
      borderStyle: `1px solid `,
      borderColor: "grey20",
      padding: "16px 12px",
    },
    blue: {
      isCurvyBox: true,
      textColor: "white",
      bgColor: "darkGrey",
      borderStyle: `1px solid `,
      borderColor: "blueCardOutline",
      padding: "16px 12px",
    },
    transparent: {
      isCurvyBox: false,
      textColor: "white",
      bgColor: "transparent",
      padding: "16px 16px 16px 0px",
    },
  },
  accountListVariants: {
    dark: {
      textColor: "white",
      boxSx: { bg: "grey90", p: 3 },
      cardContainerStyle: {
        borderBottom: "1px solid",
        borderBottomColor: "white",
      },
    },
    transparent: {
      textColor: "white",
      boxSx: { bg: "transparent", p: 0 },
      cardContainerStyle: {
        borderBottom: "1px solid",
        borderBottomColor: "grey60",
      },
    },
    light: {
      textColor: "darkGrey",
      boxSx: { bg: "greyBG", p: 3 },
      cardContainerStyle: { marginBottom: "12px" },
    },
    blue: {
      textColor: "white",
      boxSx: { bg: "darkGrey", p: 3 },
      cardContainerStyle: { marginBottom: "12px" },
    },
  },
  accountConnectionCardVariants: {
    dark: {
      cardSx: {
        bg: "grey90",
        color: "white",
        borderRadius: 15,
      },
      topSx: {
        p: "16px 16px 0 16px",
      },
      bottomSx: {
        p: "0 16px 16px 16px",
        borderTop: "1px solid",
        borderTopColor: "grey60",
      },
      labelTopSx: { marginBottom: "8px" },
      labelBottomSx: { marginTop: "12px" },
      multiAccDdlSx: {
        userSelect: "none",
        cursor: "pointer",
        alignItems: "center",
      },
      innerContentSx: { p: "0 16px 0 16px" },
    },
    light: {
      cardSx: {
        bg: "transparent",
        color: "white",
        borderRadius: 15,
        border: "1px solid",
        borderColor: "grey60",
      },
      topSx: {
        p: "16px 16px 0 16px",
      },
      bottomSx: {
        p: "0 16px 16px 16px",
        borderTop: "1px solid",
        borderTopColor: "grey60",
      },
      labelTopSx: { marginBottom: "8px" },
      labelBottomSx: { marginTop: "12px" },
      multiAccDdlSx: {
        userSelect: "none",
        cursor: "pointer",
        alignItems: "center",
      },
      innerContentSx: { p: "0 16px 0 16px" },
    },
    blue: {
      cardSx: {},
      topSx: {},
      bottomSx: {},
      labelTopSx: {},
      labelBottomSx: {},
      innerContentSx: {},
      multiAccDdlSx: {},
    },
  },
  accountConnListVariants: {
    dark: {
      textColor: "white",
      boxSx: { bg: "grey90", p: 3 },
      cardContainerStyle: {
        borderBottom: "1px solid",
        borderBottomColor: "white",
      },
    },
    light: {
      textColor: "darkGrey",
      boxSx: { bg: "greyBG", p: 3 },
      cardContainerStyle: { marginBottom: "12px" },
    },
    blue: {
      textColor: "white",
      boxSx: { bg: "darkGrey", p: 3 },
      cardContainerStyle: { marginBottom: "20px" },
    },
  },
  text: {
    // ----------------------------------------------------
    // Headings
    // ----------------------------------------------------
    h1: {
      fontFamily: "heading",
      lineHeight: "30px",
      fontWeight: 600,
      fontSize: 28,
    },
    h2: {
      fontFamily: "heading",
      lineHeight: "30px",
      fontWeight: 600,
      fontSize: 24,
    },
    h3: {
      fontFamily: "heading",
      lineHeight: "26px",
      fontWeight: 600,
      fontSize: 22,
    },
    h4: {
      fontFamily: "heading",
      lineHeight: "22px",
      fontWeight: 600,
      fontSize: 20,
    },
    // ----------------------------------------------------
    // Labels
    // ----------------------------------------------------
    labelTagLight: {
      fontFamily: "regular",
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: 13,
      backgroundColor: "grey10",
      borderRadius: "30px",
      padding: "2px 16px",
      width: "fit-content",
      marginBottom: "10px",
    },
    labelSmallRegular: {
      fontFamily: "regular",
      fontWeight: 500,
      lineHeight: "17px",
      fontSize: 13,
    },
    labelSmallRegularLite: {
      fontFamily: "regular",
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: 13,
      marginTop: "4px",
    },
    labelSmallRegularDark: {
      fontFamily: "regular",
      fontWeight: 700,
      lineHeight: "16px",
      fontSize: 13,
    },
    labelSmallMedium: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "16px",
      fontSize: 13,
    },
    labelMediumRegular: {
      fontFamily: "regular",
      fontWeight: 500,
      lineHeight: "17px",
      fontSize: 15,
    },
    labelMediumRegularLite: {
      fontFamily: "regular",
      fontWeight: 400,
      lineHeight: "17px",
      fontSize: 15,
    },
    labelMediumMedium: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "17px",
      fontSize: 15,
    },
    labelLargeRegular: {
      fontFamily: "regular",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 18,
    },
    labelLargeMedium: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 18,
    },
    // ----------------------------------------------------
    // TextLinks
    // ----------------------------------------------------
    textLinkLarge: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 18,
    },
    textLinkMedium: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "17px",
      fontSize: 15,
    },
    textLinkSmall: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 13,
    },
    textLinkExtraSmall: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "15px",
      fontSize: 12,
    },
    // ----------------------------------------------------
    // CTAs
    // ----------------------------------------------------
    ctaLarge: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 18,
    },
    ctaMedium: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "17px",
      fontSize: 15,
    },
    ctaSmall: {
      fontFamily: "medium",
      fontWeight: 700,
      lineHeight: "20px",
      fontSize: 13,
    },
    ctaTextLink: {
      fontFamily: "medium",
      fontWeight: 700,
      lineHeight: "16px",
      fontSize: 13,
      color: "orangeAction",
      m: "auto 16px",
      userSelect: "none",
      cursor: "pointer",
      alignItems: "center",
    },
    ctaSmallLetters: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: 13,
    },
    ctaExtraSmall: {
      fontFamily: "medium",
      fontWeight: 500,
      lineHeight: "15px",
      fontSize: 12,
    },
    // ----------------------------------------------------
    // Paragraphs
    // ----------------------------------------------------
    paraExtraSmallRegular: {
      fontFamily: "regular",
      lineHeight: "15px",
      fontSize: 12,
    },
    paraExtraSmallMedium: {
      fontFamily: "medium",
      lineHeight: "15px",
      fontSize: 12,
    },
    paraSmallRegular: {
      fontFamily: "regular",
      lineHeight: "18px",
      fontSize: 13,
    },
    paraSmallMedium: {
      fontFamily: "medium",
      lineHeight: "18px",
      fontSize: 13,
    },
    paraMediumRegular: {
      fontFamily: "regular",
      lineHeight: "20px",
      fontSize: 15,
    },
    paraMediumMedium: {
      fontFamily: "medium",
      lineHeight: "20px",
      fontSize: 15,
    },
    paraLargeRegular: {
      fontFamily: "regular",
      lineHeight: "26px",
      fontSize: 18,
    },
    paraLargeMedium: {
      fontFamily: "medium",
      lineHeight: "26px",
      fontSize: 18,
    },

    // ----------------------------------------------------
    // Other
    // ----------------------------------------------------
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    sub: {
      fontFamily: "body",
      fontWeight: 400,
      lineHeight: "body",
      fontSize: [4],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
    action: {
      fontFamily: "body",
      fontWeight: 400,
      lineHeight: "body",
      fontSize: [4],
    },
    body: {
      fontFamily: "body",
      fontWeight: 400,
      lineHeight: "body",
      fontSize: 4,
    },
    err: {
      fontFamily: "body",
      fontWeight: 100,
      lineHeight: "body",
      fontSize: [3],
      color: "red",
    },
    refreshText: {
      fontFamily: "regular",
      fontWeight: 700,
      lineHeight: "16px",
      fontSize: 13,
      color: "white",
    },
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle",
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
    },
    link: {
      color: "primary",
    },
    nav: {
      fontSize: 1,
      fontWeight: "bold",
      display: "inline-block",
      p: 2,
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary",
      },
    },
    manageAccounts: { minHeight: "100vh", bg: "darkGrey" },
    myAccountsBox: { minHeight: "75vh", bg: "greyBG" },
    addAccount: { p: "0 16px 100px 16px" },
  },
  /*forms: {
    input: {
      fontWeight: 800,
      fontSize: [5],
      border: "none",
      outline: "none",
    },
    select: {
      borderRadius: 9999,
    },
    textarea: {},
    label: {
      cursor: "pointer",
      userSelect: "none",
      fontFamily: "body",
      fontWeight: 400,
      lineHeight: "body",
      fontSize: [2],
    },
    radio: {},
    checkbox: {
      height: 30,
      userSelect: "none",
    },
  },*/

  // ----------------------------------------------------
  // ----------------------------------------------------
  // Buttons
  // ----------------------------------------------------
  // ----------------------------------------------------

  buttons: {
    //
    // large Icon Button
    //
    largeIconButton: {
      userSelect: "none",
      outline: "none",
      bg: "transparent",
      svg: {
        height: 40,
        width: 40,
      },
    },

    //
    // Medium Icon Button
    //
    mediumIconButton: {
      userSelect: "none",
      outline: "none",
      bg: "transparent",
      svg: {
        height: 30,
        width: 30,
      },
    },

    //
    // Small Icon Button
    //
    smallIconButton: {
      userSelect: "none",
      outline: "none",
      bg: "transparent",
      svg: {
        height: 20,
        width: 20,
      },
    },

    //
    // large
    //
    largePrimaryDark: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      padding: "16px 24px",
      fontSize: 13,
      lineHeight: "16px",
      color: "white",
      bg: "darkGrey",
      svg: {
        fill: "white",
      },
      "&:hover": {
        bg: "grey90",
      },
    },
    largePrimaryLight: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 56,
      padding: "16px 24px",
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "white",
      svg: {
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey20",
      },
    },
    largeSecondaryGrey: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 56,
      padding: "16px 24px",
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "grey20",
      svg: {
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey40",
      },
    },
    largeSecondaryOutline: {
      borderRadius: 20,
      border: "dashed",
      borderWidth: 1,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 56,
      padding: "16px 24px",
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "white",
      svg: {
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey10",
      },
    },
    largeSecondaryOutlineDark: {
      borderRadius: 20,
      border: "dashed",
      borderWidth: 1,
      userSelect: "none",
      cursor: "pointer",
      outline: "none",
      fontFamily: "regular",
      height: 56,
      padding: "16px 24px",
      fontSize: 13,
      fontWeight: 700,
      lineHeight: "16px",
      color: "white",
      bg: "darkGrey",
      svg: {
        fill: "white",
      },
      "&:hover": {
        bg: "grey90",
      },
    },
    //
    // medium
    //
    mediumPrimaryDark: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 34,
      fontSize: 13,
      lineHeight: "16px",
      color: "white",
      bg: "darkGrey",
      svg: {
        height: 18,
        fill: "white",
      },
      "&:hover": {
        bg: "grey90",
      },
    },
    mediumPrimaryLight: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 34,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "14px",
      color: "darkGrey",
      bg: "white",
      p: "4px 12px",
      svg: {
        height: 18,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey20",
      },
      cursor: "pointer",
      alignItems: "center",
    },
    mediumSecondaryGrey: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 34,
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "grey20",
      svg: {
        height: 18,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey40",
      },
    },
    mediumSecondaryOutline: {
      borderRadius: 20,
      border: "dashed",
      borderWidth: 1,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 34,
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "white",
      svg: {
        height: 17,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey10",
      },
    },
    //
    // small
    //
    smallPrimaryDark: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      // height: 26,
      fontSize: 12,
      lineHeight: "12px",
      color: "white",
      bg: "darkGrey",
      svg: {
        height: 16,
        width: 16,
        fill: "white",
      },
      "&:hover": {
        bg: "grey90",
      },
    },
    smallPrimaryLight: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      // height: 26,
      fontSize: 12,
      lineHeight: "12px",
      color: "darkGrey",
      bg: "white",
      svg: {
        height: 12,
        width: 12,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey20",
      },
    },
    smallSecondaryGrey: {
      borderRadius: 100,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      // height: 26,
      fontSize: 12,
      lineHeight: "12px",
      color: "darkGrey",
      bg: "grey20",
      svg: {
        height: 12,
        width: 12,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey40",
      },
    },
    smallSecondaryOutline: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 3,
      paddingRight: 3,
      borderRadius: 20,
      border: "dashed",
      borderWidth: 1,
      userSelect: "none",
      outline: "none",
      fontFamily: "medium",
      height: 26,
      fontSize: 13,
      lineHeight: "16px",
      color: "darkGrey",
      bg: "white",
      svg: {
        height: 12,
        width: 12,
        fill: "darkGrey",
      },
      "&:hover": {
        bg: "grey10",
      },
    },
  },

  forms: {
    input: {
      fontFamily: "medium",
      fontSize: 22,
      border: "none",
      outline: "none",
    },
    select: {
      borderRadius: 9999,
    },
    textarea: {},
    label: {
      cursor: "pointer",
      userSelect: "none",
      fontFamily: "body",
      fontWeight: 400,
      lineHeight: "body",
      fontSize: [2],
    },
    radio: {},
    checkbox: {
      height: 30,
      userSelect: "none",
    },
  },

  notificationMessage: {
    darkSuccess: {
      background: "darkGrey",
      textColor: "white",
    },
    darkError: {
      background: "darkGrey",
      textColor: "white",
    },
    lightSuccess: {
      background: "none",
      textColor: "black",
    },
    lightError: {
      background: "none",
      textColor: "black",
    },
  },

  // buttons: {
  //   primary: {
  //     fontSize: 2,
  //     fontWeight: "bold",
  //     color: "background",
  //     bg: "primary",
  //     borderRadius: "default",
  //   },
  //   outline: {
  //     variant: "buttons.primary",
  //     color: "primary",
  //     bg: "transparent",
  //     boxShadow: "inset 0 0 2px",
  //   },
  //   secondary: {
  //     variant: "buttons.primary",
  //     color: "background",
  //     bg: "secondary",
  //   },
  // },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    link: {
      textDecoration: "none",
    },
  },
  // Alert Component - Start
  alertGlobalBox: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  alertImage: {
    float: "left",
    margin: "8px 4px 8px 8px"
  },
  alertMessage: {
    fontFamily: "regular",
    fontWeight: 500,
    lineHeight: "17px",
    fontSize: 13,
    padding: "10px 8px 10px 0px",
    textAlign: "left",
    width: "100%",
  },
  // Alert Component - End
  // FormText Component - Start
  formTextGlobalBox: {
    backgroundColor: "white",
    border: "1px solid",
    borderRadius: 15,
  },
  formTextErrorBox: {
    borderTop: "1px solid",
    borderColor: "redBase",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    padding: "12px 16px 16px 16px",
  },
  formTextNoErrorBox: {
    borderTop: "1px solid",
    borderColor: "grey20",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    padding: "12px 16px 20px 16px",
  },
  formTextPaddingNoContent: {
    padding: "16px",
  },
  formTextPaddingErrorContent: {
    padding: "16px 16px 12px 16px",
  },
  // FormText Component - End
};

export default preset;

import React from "react";
import { Flex } from "rebass";

import { RenewError } from "^pages/renew-account/renew-error/renew-error.component";
import { RenewSuccess } from "^pages/renew-account/renew-success/renew-success.component";
import { preset } from "^style/preset";

export default (props, renewStatus) => {

  return (
    <Flex flexDirection="column" bg={preset.colors.darkGrey}>
      {renewStatus.status ? (
        <RenewSuccess renewStatus={renewStatus} />
      ) : (
        <RenewError renewStatus={renewStatus} />
      )}
    </Flex>
  );
};

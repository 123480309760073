import Provider from "assets/icons/monzo.png";
import React from "react";
import { Box, Flex } from "rebass";
import { preset } from "style/preset";

import { ReactComponent as ArrowRight } from "^assets/icons/arrowRight.svg";
import { ButtonLarge } from "^atoms/buttons/button.large.component";
import { NotificationMessage } from "^atoms/notification-message/notification-message.component";
import { CTA } from "^atoms/typography/cta.component";
import { APP } from "^constants/app.constant";
import { MESSAGES } from "^constants/message.constants";
import { AccountList } from "^molecules/account-list/account-list.component";
import { UTILS } from "^utilities/common";

export default (
  props,
  { handleConnectAnotherAccount, handleNavigateToDashboard },
  items = []
) => {
  const { CONNECT_ACCOUNT_MESSAGES, COMMON, APP_NAME } = MESSAGES;
  const cardItems = items.map((item) => {
    const { accountName, type } = item;
    return {
      imgSrc: Provider,
      title: accountName,
      subText: type,
    };
  });
  return (
    <Box bg={preset.colors.darkGrey} sx={{ height: "100vh" }}>
      <Flex flexDirection="column" bg={preset.colors.darkGrey}>
        <Flex mt={4}>
          <NotificationMessage
            variant={APP.NOTIFICATION_MESSAGE.DARK_SUCCESS}
            headerMessage={CONNECT_ACCOUNT_MESSAGES.SUCCESS_MESSAGE}
            message={UTILS.placeholderFormatter(
              CONNECT_ACCOUNT_MESSAGES.SUCCESS_SUB_MESSAGE,
              APP_NAME,
              "Halifax",
              90
            )}
          />
        </Flex>

        <Flex flexDirection="column" mt={2}>
          <AccountList
            cardItems={cardItems}
            cardVariant={APP.ACCOUNT_CARD_TYPE.BLUE}
            listVariant={APP.ACCOUNT_LIST_TYPE.BLUE}
          />
        </Flex>
        <Flex justifyContent="center" mt={2} mb={1}>
          <ButtonLarge
            variant={APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT}
            text={COMMON.GOTO_MY_DASHBOARD}
            onClick={handleNavigateToDashboard}
          />
        </Flex>

        <Flex justifyContent="center" mt={3} mb={3}>
          <CTA
            variant={APP.CTA_VARIANT.CTA_SMALL_LETTERS}
            color={preset.colors.white}
            sx={{ float: "left" }}
            onClick={handleConnectAnotherAccount}
          >
            {CONNECT_ACCOUNT_MESSAGES.CONNECT_ANOTHER_ACCOUNT}
          </CTA>
          <ArrowRight fill={preset.colors.white} />
        </Flex>
      </Flex>
    </Box>
  );
};

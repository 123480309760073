import PropTypes from "prop-types";
import React from "react";
import { Box, Button as RebassButton, Flex, Text } from "rebass";
import { Icon } from "^atoms/icon/icon.component";

import { APP } from "^constants/app.constant";

export const ButtonLarge = ({
  variant,
  text,
  disabled,
  icon,
  iconPos,
  textVariant,
  buttonSx,
  ...props
}) => (
  <RebassButton
    {...props}
    data-cy={`cy-${props.id}`}
    variant={variant}
    sx={{
      ...buttonSx,
      opacity: !disabled ? 1 : 0.5,
      pointerEvents: !disabled ? true : "none",
    }}
  >
    <Flex alignItems="center" sx={{ margin: "auto", width: "fit-content" }}>
      {icon && iconPos === "left" && <Icon icon={icon} iconPos={iconPos} />}
      <ButtonText text={text} textVariant={textVariant} />
      {icon && iconPos === "right" && <Icon icon={icon} iconPos={iconPos} />}
    </Flex>
  </RebassButton>
);

const ButtonText = ({ text, textVariant }) => (
  <Text variant={textVariant}>{text}</Text>
);

ButtonLarge.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.any,
  iconPos: PropTypes.oneOf(["right", "left"]),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  textVariant: PropTypes.string,
  buttonSx: PropTypes.object,
  variant: PropTypes.oneOf([
    APP.BUTTON_TYPE.LARGE_PRIMARY_DARK,
    APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT,
    APP.BUTTON_TYPE.LARGE_SECONDARY_GREY,
    APP.BUTTON_TYPE.LARGE_SECONDARY_OUTLINE,
    APP.BUTTON_TYPE.LARGE_SECONDARY_OUTLINE_DARK,
  ]),
};

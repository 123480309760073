import { useNavigate } from "@reach/router";

import template from "./connect-bank-failure.template";

export const ConnectFailure = (props) => {
  const navigate = useNavigate();

  const events$ = {
    handleNavigateToDashboard: null,
    handleNavigateDiffAccount: null,
  };
  events$.handleNavigateToDashboard = () => {
    navigate("dashboard");
  };
  events$.handleNavigateDiffAccount = () => {
    navigate("connectbank");
  };
  return template(props, events$);
};

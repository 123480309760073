import development from "./env.dev";
import local from "./env.local";
import production from "./env.prod";
import test from "./env.test";

const config =
  process.env.REACT_APP_ENVIRONMENT === "Production"
    ? production
    : process.env.REACT_APP_ENVIRONMENT === "Test"
    ? test
    : process.env.REACT_APP_ENVIRONMENT === "Development"
    ? development
    : local;

export default {
  //Add common config values here
  ...config,
};

import { Link } from "@reach/router";
import { ReactComponent as ArrowLeft } from "assets/icons/arrowLeft.svg";
import { ReactComponent as Blank } from "assets/icons/blank.svg";
import { ReactComponent as Burger } from "assets/icons/burgerNav.svg";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMeasure } from "react-use";
import { Box, Flex } from "rebass";
import { navBarHeightAction } from "reducers/appReducer";
import { preset } from "style/preset";

import { IconButton } from "^atoms/buttons/icon.button.component";
import { H4 } from "^atoms/typography/heading.component";

export const NavBar = ({
  bg,
  fg,
  setIsNavOpen,
  regScreens = false,
  showArrowLeft = false,
  isNavOpen,
  handleNavigation,
  textColor = preset.colors.white,
  noBurgerIcon = false,
  navigateTo = "",
  fillColor = preset.colors.black,
  ...props
}) => {
  const [ref, { height }] = useMeasure();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!regScreens) {
      dispatch(navBarHeightAction(height));
    }
  }, [height, dispatch]);

  return (
    <nav>
      <Box bg={bg} ref={ref}>
        <Flex alignItems="center" p={3}>
          <Flex justifyContent="flex-start">
            {showArrowLeft ? (
              <ArrowLeft
                fill={fillColor}
                height={30}
                width={30}
                onClick={handleNavigation}
              />
            ) : (
              <Flex justifyContent="flex-start">
                <Blank />
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="center" flexGrow={1}>
            <H4
              sx={{
                fontFamily: "medium",
                color: textColor,
                fontSize: 22,
                opacity: 0.2,
              }}
            >
              {regScreens || navigateTo ? (
                <Link
                  to={regScreens ? "/" : navigateTo}
                  style={preset.styles.link}
                >
                  <Box sx={{ textDecoration: "none", color: textColor }}>
                    Logo
                  </Box>
                </Link>
              ) : (
                "Logo"
              )}
            </H4>
          </Flex>
          {!noBurgerIcon ? (
            <Flex
              justifyContent="flex-end"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <IconButton icon={Burger} variant="mediumIconButton" fg="white" />
            </Flex>
          ) : (
            <Flex justifyContent="flex-end">
              <Blank />
            </Flex>
          )}
        </Flex>
      </Box>
    </nav>
  );
};

NavBar.propTypes = {
  setIsNavOpen: PropTypes.func,
  isNavOpen: PropTypes.bool,
  bg: PropTypes.string,
  fg: PropTypes.string,
  regScreens: PropTypes.bool,
  showArrowLeft: PropTypes.bool,
  handleNavigation: PropTypes.func,
  textColor: PropTypes.string,
  noBurgerIcon: PropTypes.bool,
  navigateTo: PropTypes.string,
  fillColor: PropTypes.string,
};

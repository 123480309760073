import PropTypes from "prop-types";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box, Button as RebassButton, Flex } from "rebass";
import { APP } from "utilities/constants/app.constant";
import { Icon } from "^atoms/icon/icon.component";

export const Button = ({
  variant,
  text,
  disabled,
  icon,
  iconPos,
  ...props
}) => {
  const [clicked, set] = useState(false);
  const { scale } = useSpring({ scale: clicked ? 0.8 : 1 });
  const AnimatedButton = animated(RebassButton);

  // eslint-disable-next-line no-unused-vars
  const animate = () => {
    set(true);
    setTimeout(() => {
      set(false);
    }, 100);
  };

  return (
    <AnimatedButton
      {...props}
      variant={variant}
      style={{
        userSelect: "none",
        opacity: !disabled ? 1 : 0.5,
        pointerEvents: !disabled ? true : "none",
        transform: scale.interpolate((s) => `scale(${s})`),
      }}
    >
      <Flex alignItems="center">
        {icon && iconPos === "left" && <Icon icon={icon} iconPos={iconPos} />}
        <ButtonText text={text} />
        {icon && iconPos === "right" && <Icon icon={icon} iconPos={iconPos} />}
      </Flex>
    </AnimatedButton>
  );
};

const ButtonText = ({ text }) => <Box>{text}</Box>;

ButtonText.propTypes = {
  text: PropTypes.string,
};

Button.propTypes = {
  icon: PropTypes.any,
  iconPos: PropTypes.oneOf(["right", "left"]),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    APP.BUTTON_TYPE.LARGE_PRIMARY_DARK,
    APP.BUTTON_TYPE.LARGE_PRIMARY_LIGHT,
    APP.BUTTON_TYPE.LARGE_SECONDARY_GREY,
    APP.BUTTON_TYPE.LARGE_SECONDARY_OUTLINE,
    APP.BUTTON_TYPE.MEDIUM_PRIMARY_DARK,
    APP.BUTTON_TYPE.MEDIUM_PRIMARY_LIGHT,
    APP.BUTTON_TYPE.MEDIUM_SECONDARY_GREY,
    APP.BUTTON_TYPE.MEDIUM_SECONDARY_OUTLINE,
    APP.BUTTON_TYPE.SMALL_PRIMARY_DARK,
    APP.BUTTON_TYPE.SMALL_PRIMARY_LIGHT,
    APP.BUTTON_TYPE.SMALL_SECONDARY_GREY,
    APP.BUTTON_TYPE.SMALL_SECONDARY_OUTLINE,
  ]),
};
